import { CSSObject } from 'tss-react';

import { appColors } from '@/Providers/ThemeProvider/colors';

export const defaultTabStyles: CSSObject = {
  textTransform: 'none',
  minWidth: 0,
  paddingLeft: '1.5em',
  paddingRight: '1.5em',
  color: appColors.black,
  fontWeight: 600,

  '&.Mui-selected': {
    color: appColors.primary,
  },

  '@media (min-width:600px)': {
    minWidth: 0,
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
  },
};
