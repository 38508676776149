import { action, makeObservable, observable } from 'mobx';

import { IProgressProps } from '@/types';

export default class ProgressModel {
  constructor() {
    makeObservable(this);
  }
  @observable progressStages: IProgressProps[] | [] = []; //? получаю из REST-запроса список всех стадий

  @action setStages = (stages: IProgressProps[]) => {
    this.progressStages = stages;
  };
}
