import { IGroup } from './group';

export interface Device {
  id: number;
  deviceId: string;
  creatorId: number;
  creatorUsername: string;
  status: DeviceStatus;
  dateModified: string | null;
  dateRegistered: string;
  networkAddress: string | null;
  name: string | null;
  softwareVersion: string;
  department: IGroup | null;
  authorizationType: AuthorizationType;
  accessToPersonCard: AccessToPersonCard;
  isBlocked: boolean | null;
  updateRequired: string | null;
  lastActivityDate: string;
  token: string;
}

export enum DeviceStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  UPDATING = 'UPDATING',
  ERROR = 'ERROR',
}

export enum AuthorizationType {
  WITHOUT_AUTHORIZATION = 'WITHOUT_AUTHORIZATION',
  QR_CODE = 'QR_CODE',
}

export enum AccessToPersonCard {
  WITH_CREDENTIALS = 'WITH_CREDENTIALS',
  WITHOUT_CREDENTIALS = 'WITHOUT_CREDENTIALS',
}

export type UpdateDeviceSettingsDto = Pick<
  Device,
  'name' | 'isBlocked' | 'accessToPersonCard' | 'authorizationType'
> & { departmentId: string | null };

export type DeviceSoftware = {
  id: number;
  version: string;
  createdDate: string;
  fileSizeMb: number;
};
