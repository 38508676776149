import bind from '@chbrown/bind';

import { action, computed, makeObservable, observable } from 'mobx';

import _ from 'lodash';

import { ESwitch } from './types';

import { MockStore } from '@/stores/MockStore.ts';
import {
  convertInterfaceToFieldsName,
  fillValuesAtFields,
  IFieldTitle,
} from '@/utils/convertTargetsInfo';

import { sleep } from '@/utils/sleep';

import {
  ESearchBaseInfo,
  IGetReportEtalonsProps,
  IPutNormalizedProps,
  IPutReportMarkerProps,
  IT4FaceSearchReportEtalonInfo,
  MARKERS,
  ProgressStages,
  SnackbarActionType,
  TTargetFileBase,
} from '@/types';
import searchRequestService from '@/services/searchQuery.service';
import { IT4FaceSearchBaseInfo } from '@/apiTypes';
import taskApi from '@/services/task.service';
import { getApiRequestFileUrl } from '@/utils/FilesUtil';

const DEFAULTetalonsByPage = 20;

class CTargetsEtalonsStore {
  constructor() {
    makeObservable(this);
  }
  @observable isPendingInit = false;

  @observable isPendingTargets = false;
  @observable targetsOriginal?: IT4FaceSearchBaseInfo = undefined;
  @observable targets?: TTargetFileBase[] = undefined;
  @observable targetFile?: TTargetFileBase = undefined;
  @observable selectTargetId = 0;

  @observable isPendingEtalons = false;
  @observable etalons?: IT4FaceSearchReportEtalonInfo[] = undefined;
  @observable etalon?: IT4FaceSearchReportEtalonInfo = undefined;

  etalonCurrentPage = 0;
  etalonsByPage = DEFAULTetalonsByPage;
  etalonsTotalPages = -1;

  @observable selectEtalonIndex = 0;
  @observable selectEtalonInfoIndex = 0;
  @observable tempMarkerForEtalons: Map<string, MARKERS> = new Map<string, MARKERS>();

  @observable isPendingNormalize = false;
  @observable normalizeImage?: string = undefined;
  @observable onSwitchNormalize = false;

  private snackbarAction?: SnackbarActionType;

  @action setEtalonsTotalPages(value: number) {
    this.etalonsTotalPages = value;
  }

  @action setEtalonCurrentPage(value: number) {
    this.etalonCurrentPage = value;
  }

  @action setEtalonsByPage(value: number) {
    this.etalonsByPage = value;
  }

  @action setOnSwitchNormalizeAc(state: boolean) {
    this.onSwitchNormalize = state;
  }

  @action
  private setPendingTargets(value: boolean): void {
    this.isPendingTargets = value;
  }

  @action
  private setPendingEtalons(value: boolean): void {
    this.isPendingEtalons = value;
  }

  @action
  private setPendingInit(value: boolean): void {
    this.isPendingInit = value;
  }

  @action
  private setTargets(value: IT4FaceSearchBaseInfo | undefined): void {
    this.targetsOriginal = value;
    this.targets = _.flatten(
      value?.target_files.map((tf) =>
        tf.models.map((m) => {
          return { file_name: tf.file_name, ...m };
        })
      )
    );
  }

  @action
  private setTargetFile(value: TTargetFileBase | undefined): void {
    if (this.targetFile !== value) {
      this.resetEtalons();
    }
    this.targetFile = value;
  }

  @action
  private setSelectEtalonIndex(index: number) {
    this.selectEtalonInfoIndex = 0;
    this.selectEtalonIndex = index;
  }

  @action
  private setEtalon(etalon: IT4FaceSearchReportEtalonInfo | undefined) {
    if (!etalon) {
      this.normalizeImage = undefined;
      this.onSwitchNormalize = false;
      this.selectEtalonInfoIndex = 0;
      this.selectEtalonIndex = 0;
    }
    this.etalon = etalon;
    if (etalon) {
      this.extractNormalizedUrlFromInfo();
    }
  }

  @action
  setPendingNormalize(state: boolean): void {
    this.isPendingNormalize = state;
  }

  @action
  setSelectEtalonInfoByIndex(index: number) {
    this.selectEtalonInfoIndex = index;
    this.extractNormalizedUrlFromInfo();
  }

  @bind
  setOnSwitchNormalize(state: boolean) {
    this.setOnSwitchNormalizeAc(state);
  }

  @action
  setNormalizeImage(url: string | undefined) {
    this.normalizeImage = url;
  }

  @bind
  setEtalonByIndex(index: number) {
    this.setSelectEtalonIndex(index);
    this.setEtalon(this.etalons?.[index]);
  }

  @bind
  setTargetFileById(id: number): void {
    this.resetEtalons();
    this.targets && this.setTargetFile(this.targets[id]);
    this.setSelectTargetId(id);
  }

  @bind
  switchTarget(forward: ESwitch): void {
    const length = this.targets?.length ?? 0;
    if (length > 1) {
      let selectTargetId = this.selectTargetId;
      if (forward === ESwitch.FORWARD) {
        if (this.selectTargetId + 1 === length) {
          selectTargetId = 0;
        } else {
          selectTargetId = this.selectTargetId + 1;
        }
      } else {
        if (this.selectTargetId - 1 < 0) {
          selectTargetId = length - 1;
        } else {
          selectTargetId = this.selectTargetId - 1;
        }
      }

      this.resetEtalons();
      this.setTargetFile(this.targets && this.targets[selectTargetId]);
      this.setSelectTargetId(selectTargetId);
    }
  }

  @bind
  async switchEtalon(forward: ESwitch): Promise<void> {
    //сбрасываем значение подэталона
    this.setSelectEtalonInfoByIndex(0);
    //очищаем нормализованный урл
    this.setNormalizeImage(undefined);

    this.setOnSwitchNormalize(false);

    const length = this.etalons?.length ?? 0;
    if (length > 1) {
      let selectEtalonIndex;
      if (forward === ESwitch.FORWARD) {
        if (this.selectEtalonIndex + 1 === length) {
          if (this.etalonCurrentPage === this.etalonsTotalPages) {
            selectEtalonIndex = 0;
          } else {
            await this.getEtalonsCurrentPage();
            selectEtalonIndex = this.selectEtalonIndex + 1;
          }
        } else {
          selectEtalonIndex = this.selectEtalonIndex + 1;
        }
      } else {
        if (this.selectEtalonIndex - 1 < 0) {
          selectEtalonIndex = length - 1;
        } else {
          selectEtalonIndex = this.selectEtalonIndex - 1;
        }
      }

      this.setEtalonByIndex(selectEtalonIndex);
    }
  }

  @bind
  switchEtalonInfo(forward: ESwitch): void {
    const length = this.etalon?.etalons_info.length ?? 0;
    if (length > 1) {
      //очищаем нормализованный урл
      this.setNormalizeImage(undefined);
      this.setOnSwitchNormalize(false);

      let selectEtalonInfoIndex: number;

      if (forward === ESwitch.FORWARD) {
        if (this.selectEtalonInfoIndex + 1 === length) {
          selectEtalonInfoIndex = 0;
        } else {
          selectEtalonInfoIndex = this.selectEtalonInfoIndex + 1;
        }
      } else {
        if (this.selectEtalonInfoIndex - 1 < 0) {
          selectEtalonInfoIndex = length - 1;
        } else {
          selectEtalonInfoIndex = this.selectEtalonInfoIndex - 1;
        }
      }

      this.setSelectEtalonInfoByIndex(selectEtalonInfoIndex);
    }
  }

  @action
  private setEtalons(value: IT4FaceSearchReportEtalonInfo[] | undefined): void {
    this.etalons = value;
  }

  @action
  private addEtalons(value: IT4FaceSearchReportEtalonInfo[]): void {
    this.etalons = this.etalons ? this.etalons.concat(value) : value;
  }

  @action
  private setSelectTargetId(value: number): void {
    this.selectTargetId = value;
  }

  private async getTargets(
    sqId: number,
    snackbarAction: SnackbarActionType | undefined
  ): Promise<void> {
    if (snackbarAction) {
      this.setPendingTargets(true);
      const targetsResult = await searchRequestService.getSearchQueryTargets(sqId, snackbarAction);
      if (targetsResult) {
        this.setTargets(targetsResult);

        //берем нулевой таргет
        if (this.targets && this.targets.length > 0) {
          this.setTargetFile(this.targets[0]);
        } else {
          this.setTargetFile(undefined);
        }
      }

      this.setPendingTargets(false);
    } else {
      console.log('TargetsEtalonsStore not ready');
    }
  }

  @bind
  async getEtalonsCurrentPage(): Promise<void> {
    if (!this.targetsOriginal || !this.targetFile) {
      this.setEtalons(undefined);

      return;
    }

    this.setEtalonCurrentPage(this.etalonCurrentPage + 1);
    const data = {
      sq_id: this.targetsOriginal.sq_id,
      target: this.targetFile.model,
      page: this.etalonCurrentPage,
      size: this.etalonsByPage,
    } as IGetReportEtalonsProps;

    this.setPendingEtalons(true);

    if (this.snackbarAction) {
      const etalonsPage = await searchRequestService.getSearchQueryEtalons(
        data,
        this.snackbarAction
      );
      this.setEtalonCurrentPage(etalonsPage.paging.page);
      this.setEtalonsTotalPages(etalonsPage.paging.pages_total);
      this.addEtalons(etalonsPage.etalons);

      if (etalonsPage.paging.page === 1) {
        this.setEtalonByIndex(0);
      }
      this.setPendingEtalons(false);
    } else {
      console.log('TargetsEtalonsStore not ready');
    }
  }

  @bind
  async setMarker(marker: MARKERS, etalon?: IT4FaceSearchReportEtalonInfo) {
    if (!this.targetsOriginal || !this.targetFile || !this.etalon) {
      return;
    }

    let newMarker = marker;
    let beforeMarker;
    const findTempMarker = this.tempMarkerForEtalons.get(this.etalon.card_id);

    if (findTempMarker) {
      beforeMarker = findTempMarker;
    } else {
      beforeMarker = this.etalon?.marker;
    }
    if (beforeMarker === marker) {
      newMarker = MARKERS.EMPTY;
    }

    const data: IPutReportMarkerProps = {
      sq_id: this.targetsOriginal.sq_id,
      target: this.targetFile.model,
      etalon: etalon ? etalon.etalon_model : this.etalon.etalon_model,
      marker: newMarker,
    };

    if (this.snackbarAction) {
      const result = await searchRequestService.changeSearchQueryReportMarker(
        data,
        this.snackbarAction
      );

      if (result) {
        this.tempMarkerForEtalons.set(etalon ? etalon.card_id : this.etalon.card_id, newMarker);
      }
    } else {
      console.log('TargetsEtalonsStore not ready');
    }
  }

  @bind
  async init(sqId: number, snackbarAction: SnackbarActionType | undefined) {
    if (snackbarAction) this.snackbarAction = snackbarAction;
    if (this.targetsOriginal?.sq_id === sqId) return;
    this.reset();
    this.setPendingInit(true);
    //подгружаем таргеты
    await this.getTargets(sqId, snackbarAction);
    this.setPendingInit(false);
  }

  private extractNormalizedUrlFromInfo(): boolean {
    if (this.etalon?.etalons_info[this.selectEtalonInfoIndex].normalized) {
      this.setNormalizeImage(
        encodeURIComponent(this.etalon.etalons_info[this.selectEtalonInfoIndex].normalized)
      );
      this.onSwitchNormalize = true;

      return true;
    } else {
      return false;
    }
  }

  @bind
  async startNormalize(): Promise<void> {
    if (this.extractNormalizedUrlFromInfo()) {
      return;
    }

    this.setPendingNormalize(true);
    if (this.targetsOriginal && this.targetFile && this.etalon) {
      const putData: Omit<IPutNormalizedProps, 'normalized'> = {
        sq_id: this.targetsOriginal.sq_id,
        etalon: this.etalon.etalon_model,
        etalon_img: this.etalon.etalons_info[this.selectEtalonInfoIndex].original,
      };

      if (!this.snackbarAction) {
        return;
      }

      const photoUri = this.etalon.etalons_info[this.selectEtalonInfoIndex].original;
      const errorMessage = `Не удалось создать задачу по нормализации фотографии ${photoUri}`;
      const uuid = await taskApi.createNormalizeImageTask(
        photoUri,
        this.etalon.etalon_model,
        errorMessage,
        this.snackbarAction
      );
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      await new Promise(async (resolve) => {
        let complete = false;

        if (!this.snackbarAction) {
          return;
        }

        try {
          while (!complete) {
            const taskResult = await taskApi.getTaskResult(uuid, errorMessage, this.snackbarAction);

            if (
              taskResult.status === ProgressStages.SUCCESSFUL &&
              taskResult.normalized_image_uri
            ) {
              const putDataNormalized: IPutNormalizedProps = {
                ...putData,
                normalized: taskResult.normalized_image_uri,
              };

              if (this.snackbarAction) {
                await searchRequestService.addNormalizedImage(
                  putDataNormalized,
                  this.snackbarAction
                );
              }
              this.setNormalizeImage(encodeURIComponent(taskResult.normalized_image_uri));

              if (this.etalon && this.etalons) {
                const newFaceEtalonInfo = {
                  ...this.etalon?.etalons_info[this.selectEtalonInfoIndex],
                  normalized: taskResult.normalized_image_uri,
                };

                const newEtalon: IT4FaceSearchReportEtalonInfo = {
                  ...this.etalon,
                  etalons_info: this.etalon.etalons_info.map((info, index) => {
                    return index === this.selectEtalonInfoIndex ? newFaceEtalonInfo : info;
                  }),
                };
                this.setEtalon(newEtalon);
                this.setEtalons([
                  ...this.etalons.map((etalon, index) => {
                    return index === this.selectEtalonIndex ? newEtalon : etalon;
                  }),
                ]);
              }

              resolve(taskResult);
              complete = false;

              return;
            }
            await sleep(500);
          }
        } catch {
          this.setPendingNormalize(false);
        }
      });
    }
    this.setPendingNormalize(false);
  }

  @bind
  reset() {
    this.resetEtalons();
    this.setSelectTargetId(0);
    this.setTargets(undefined);
    this.setTargetFile(undefined);
    this.targetsOriginal = undefined;
  }

  @bind
  resetEtalons() {
    // так как переключили таргет, то выбранный эталон сбрасывается, необходимо выбрать заново эталон.
    this.tempMarkerForEtalons.clear();
    this.setEtalonCurrentPage(0);
    this.setEtalonsTotalPages(-1);

    this.setSelectEtalonInfoByIndex(0);
    this.setSelectEtalonIndex(0);

    this.setEtalon(undefined);
    this.setEtalons(undefined);

    this.setNormalizeImage(undefined);
    this.setOnSwitchNormalize(false);
  }

  @computed get sqId() {
    return this.targetsOriginal?.sq_id;
  }

  @computed get selectedTarget() {
    if (!this.targets) {
      return undefined;
    }

    return this.targets[this.selectTargetId];
  }

  @computed get selectedEtalonInfo() {
    if (!this.etalon) {
      return undefined;
    }

    return this.etalon.etalons_info[this.selectEtalonInfoIndex];
  }

  @computed get targetImageUrl() {
    const urlEncode = this.targetFile?.normalized_image
      ? encodeURIComponent(this.targetFile?.normalized_image)
      : '';

    return getApiRequestFileUrl(urlEncode);
  }

  @computed get etalonImageUrl() {
    const urlEncode = this.etalon?.etalons_info[this.selectEtalonInfoIndex].original
      ? encodeURIComponent(this.etalon?.etalons_info[this.selectEtalonInfoIndex].original)
      : '';

    if (this.onSwitchNormalize && !this.isPendingNormalize && this.normalizeImage === undefined) {
      return undefined;
    }

    return getApiRequestFileUrl(
      this.onSwitchNormalize
        ? this.isPendingNormalize
          ? urlEncode
          : this.normalizeImage
        : urlEncode
    );
  }

  @computed get targetVoiceUrl() {
    const urlEncode =
      this.targetsOriginal &&
      this.targetsOriginal.target_files.length > 0 &&
      this.targetsOriginal?.target_files[0].file
        ? encodeURIComponent(this.targetsOriginal?.target_files[0].file)
        : '';

    return MockStore.backendMode
      ? this.etalon?.etalons_info[0].normalized
      : `${process.env.PUBLIC_URL}/api/v1/audio?url=${urlEncode}`;
  }

  @computed get etalonVoiceUrl() {
    const urlEncode =
      this.etalon?.etalons_info[this.selectEtalonInfoIndex] &&
      this.etalon?.etalons_info[this.selectEtalonInfoIndex].original
        ? encodeURIComponent(this.etalon?.etalons_info[this.selectEtalonInfoIndex].original)
        : '';

    return MockStore.backendMode
      ? this.etalon?.etalons_info[0].normalized
      : `${process.env.PUBLIC_URL}/api/v1/audio?url=${urlEncode}`;
  }

  @computed get etalonsUpgraded() {
    return this.etalons?.map((etalon) => {
      let marker: MARKERS;
      const stateGetTempMarker = this.tempMarkerForEtalons.get(etalon.card_id);
      if (stateGetTempMarker) {
        marker = stateGetTempMarker;
      } else {
        marker = etalon.marker;
      }

      return { ...etalon, marker };
    });
  }

  @computed get targetCollumn(): IFieldTitle[] {
    return this.targetsOriginal ? convertInterfaceToFieldsName(this.targetsOriginal.columns) : [];
  }

  @computed get etalonFieldValues(): string[] {
    return this.etalon ? fillValuesAtFields(this.etalon.fields, this.targetCollumn) : [];
  }

  @computed get isActiveButtonLeftRightSwitchEtalon(): boolean {
    return this.etalons ? this.etalons.length > 1 : false;
  }

  @computed get isVoiceBaseInfo() {
    return this.targetsOriginal?.type === ESearchBaseInfo.VOICE_SEARCH;
  }
}

export const TargetsEtalonsStore = new CTargetsEtalonsStore();
