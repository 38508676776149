import { ECardExportStatus } from '@/types/card';
import { en } from './en';
import { EFileProcessStatus } from '@/types/file';
import { AccessToPersonCard, AuthorizationType, DeviceStatus } from '@/types/device';
import { PrivilegeCode } from '@/types/role';

export const ru: typeof en = {
  appCreatorInfo: '© ООО «ЦРТ». Все права защищены.',
  files: 'Файлы',
  tasks: 'Задачи',
  searchByFileName: 'Поиск по названию файла',
  appInfo: 'Анализ биометрических множеств и иной информации',
  appName: 'Мультипаспорт',
  fileDialog: {
    prepareFilesForUploading: 'Подготовить файлы для загрузки в аналитическую систему',
    title: 'Загрузка файла',
    dragFileOrPress: 'Перетащите файлы сюда или нажмите',
    invalidFileExtention:
      'Тип {{extention}} файла {{name}} не поддерживается. Файл не был прикреплён.',
    deleteCompletedTasks: 'Удалить все завершенные задачи',
    deleteTask: 'Удаление задачи',
    deleteTaskConfirm: 'Вы уверены, что хотите удалить задачу «{{name}}»?',
    positionInQueue: 'Позиция в очереди',
  },
  add: 'Добавить',
  addUser: 'Добавить пользователя',
  admin: 'админ',
  administration: 'Администрирование',
  unknown: 'Неизвестно',
  attachedFileStatus: {
    [EFileProcessStatus.NEW]: 'Новый',
    [EFileProcessStatus.INPROGRESS]: 'В процессе',
    [EFileProcessStatus.COMPLETED]: 'Добавлен',
    [EFileProcessStatus.FAILED]: 'Ошибка',
    [EFileProcessStatus.DUPLICATE]: 'Дубликат',
  },
  attachedFiles: 'Прикрепленные файлы',
  auth: {
    confirmNewPassword: 'Повторите новый пароль',
    createNewPassword: 'Придумайте новый пароль',
    enter: 'Войти',
    enterNewPassword: 'Введите новый пароль',
    newPassword: 'Новый пароль',
    newPasswordConfirmation: 'Подтверждение нового пароля',
    title: 'Войти в Мультипаспорт',
    user: 'Пользователь',
    welcome: 'Добро пожаловать!',
    youDontHavePermissions: 'У вас недостаточно прав',
  },
  back: 'Назад',
  yes: 'Да',
  no: 'Нет',
  all: 'Все',
  apply: 'Применить',
  block: 'Заблокировать',
  stop: 'Остановить',
  from: 'от',
  warning: 'Предупреждение',
  select: 'Выбрать',
  blockUser: 'Блокировка пользователя',
  blockYourself: 'Блокировать свою учетную запись запрещено',
  cancel: 'Отменить',
  cards: {
    areYouShureDirDeleteOne: 'Вы действительно хотите удалить выбранную карточку?',
    areYouShureDirDeleteOneSeveral:
      'Вы действительно хотите удалить выбранные карточки ({{count}})?',
    biometricModels: 'Биометрические модели',
    changed: 'Дата и время изменения',
    changedBy: 'Кем изменена',
    clear: 'Очистить',
    closeCardTitle: 'Закрыть карточку',
    created: 'Дата и время создания',
    createdBy: 'Кем создана',
    createdCard: 'Карточка успешно создана',
    dateOfBirth: 'Дата рождения',
    deleteAddedInCardAudio: 'Вы действительно хотите удалить выбранный аудиофайл?',
    deleteAddedInCardAudios: 'Вы действительно хотите удалить выбранные аудиофайлы?',
    deleteAddedInCardPhoto: 'Вы действительно хотите удалить выбранное изображение?',
    deleteAddedInCardPhotos: 'Вы действительно хотите удалить выбранные изображения?',
    deleteAudioTitle: 'Удаление аудиофайла',
    deleteAudiosTitle: 'Удаление аудиофайлов',
    deleteCard: 'Удаление карточки',
    deleteDocumentConfirmation: 'Вы действительно хотите удалить выбранный документ?',
    deleteDocumentTitle: 'Удаление документа',
    deleteDocumentWithMainPhoto:
      'Вы действительно хотите удалить выбранный документ и созданную модель лица?',
    deleteDocumentsConfirmation: 'Вы действительно хотите удалить выбранные документы?',
    deleteDocumentsTitle: 'Удаление документов',
    deleteFileConfirmation: 'Вы действительно хотите удалить выбранный файл?',
    deleteFileTitle: 'Удаление файла',
    deleteFilesConfirmation: 'Вы действительно хотите удалить выбранные файлы?',
    deleteFilesTitle: 'Удаление файлов',
    deleteMainPhotoDesc:
      'Вы действительно хотите удалить выбранное изображение и созданную модель лица?',
    deletePhotoTitle: 'Удаление изображения',
    deletePhotosTitle: 'Удаление изображений',
    description: 'Комментарий',
    documentAvailabilityIndicator: 'Признак наличия документа',
    documents: 'Документы',
    export: 'Экспорт',
    exportGridStatus: 'Статус экспорта в GridID',
    exportStatus: {
      [ECardExportStatus.NEW]: 'Новая',
      [ECardExportStatus.NEED_EXPORT]: 'Создана',
      [ECardExportStatus.EXPORTED]: 'Экспортирована',
    },
    face: 'Лицо',
    faceModel: 'Модель лица',
    fullname: 'ФИО',
    gender: 'Пол',
    lastExported: 'Дата экспорта в GridID',
    models: 'Модели',
    name: 'Имя',
    noItemsAvailable: 'Нет доступных элементов',
    noItemsToShow: 'Нет элементов для отображения',
    noModel: 'Без лицевой модели',
    noUploadedImages: 'Нет загруженных изображений',
    personId: 'Паспорт',
    search: 'Введите текст',
    source: 'Источник',
    statusCreated: 'Создана',
    statusNew: 'Новая',
    succesCreateCard: 'Карточка успешно создана',
    succesDeletedСard: 'Карточка успешно удалена',
    title: 'Картотека',
    type: 'Тип',
    unsavedChangesConfirmation:
      'В карточке есть несохраненные изменения. Вы действительно хотите закрыть карточку?',
    uploadImageTobuildFaceModel: 'Чтобы построить модель лица, загрузите изображение',
    voice: 'Голос',
  },
  interactiveMode: {
    deviceRecording: 'Запись с устройств',
    startRecording: 'Начать сбор',
    stopRecording: 'Остановить сбор',
    camera: 'Камера',
    microphone: 'Микрофон',
    cameraNotSelected: 'Не выбрана',
    microphoneNotSelected: 'Не выбран',
    microphoneSwitchNotAvailable: 'Во время записи смена микрофона недоступна',
    deviceNotSelected: 'Устройство не выбрано',
    deviceConnectError: 'Не удалось подключить устройство',
    deviceConnectErrorReasons:
      'Возможные причины: \n- Устройство не работает \n- Устройство используется другим приложением \n- Доступ к устройству запрещён в настройках системы',
    startCollectingBiometrics: 'Запустите сбор  биометрии',
    autoShootMode: 'Автоматический режим съёмки',
    questionnaire: 'Опросник',
    nextQuestion: 'Следующий вопрос',
    previousQuestion: 'Предыдущий вопрос',
    questionsCounter: 'Вопрос №{{index}} из {{count}}',
    play: 'Начать запись',
    pause: 'Пауза',
    isRecording: 'идёт запись',
    recordingIsPaused: 'запись на паузе',
    pureSpeech: 'Чистая речь',
    devicesSetup: 'Настройка устройств',
    video: 'Видео',
    sound: 'Звук',
    sensitivity: 'Чувствительность',
    photoNotTaken: 'Снимок ещё не сделан',
    takePhoto: 'Сделать фото',
    maxPhotoCount: 'Максимальное количество снимков - {{count}}',
    biometricsCollectionModeIsActive: 'В карточке запущен режим сбора биометрии',
    stopRecordingOnCloseConfirmation: 'Остановить сбор биометрии и закрыть карточку?',
    deviceDisconnected: 'Нет связи с устройством',
  },
  changePassword: 'Сменить пароль',
  changePasswordTitle: 'Смена пароля',
  chooseRole: 'Выберите роль',
  close: 'Закрыть',
  collapse: 'Свернуть',
  commentary: 'Комментарий',
  copyCardId: 'Копировать ID карточки',
  copyId: 'Копировать ID',
  create: 'Создать',
  createRole: 'Создать роль',
  createUser: 'Создать пользователя',
  delete: 'Удалить',
  download: 'Скачать',
  edit: 'Редактировать',
  enrollment: 'Картотека',
  enterComment: 'Введите комментарий',
  enterFirstname: 'Введите имя',
  enterFullName: 'Введите ФИО',
  enterLastname: 'Введите фамилию',
  enterMiddlename: 'Введите отчество',
  enterNewPassword: 'Введите новый пароль',
  enterPassword: 'Введите пароль',
  enterUsername: 'Введите логин',
  error: 'Ошибка',
  errorOccurred: 'Произошла ошибка',
  unknownError: 'Неизвестная ошибка',
  exclude: 'Исключить',
  exit: 'Завершить',
  fieldError: {
    InvalidCount: 'Ошибка в количестве значений в поле',
    InvalidDataType: 'Ошибка в типе данных в поле',
    InvalidDateFormat: 'Ошибка в формате даты',
    InvalidDateTimeFormat: 'Ошибка в формате времени',
    InvalidValue: 'Введенное значение не соответствует формату',
    MissingRequired: 'Не заполнено обязательное поле',
    NotUniqueValue: 'Введенное значение не уникально',
    UnknownCustomType: 'Неизвестный тип поля',
    UnknownField: 'Неизвестное поле',
    error: 'Неверно заполнено поле "{{field}}": ',
  },
  fileInProgress: 'Файл в процессе обработки',
  fileIsNotFound: 'Файл не найден',
  fileWasPreviouslyUploaded: 'Файл был загружен в систему ранее с названием «{{name}}»',
  firstname: 'Имя',
  fullname: 'ФИО',
  gallery: 'Режим галереи',
  gridTable: {
    hideCol: 'Спрятать столбец',
    showCols: 'Настройки',
    sortAsc: 'Сортировать по возрастанию',
    sortDesc: 'Сортировать по убыванию',
    unsort: 'Сбросить сортировку',
  },
  group: 'Подразделение',
  groups: {
    addUser: 'Добавление пользователя',
    confirmDelete: 'Вы действительно хотите удалить подразделение «{{name}}» ?',
    confirmDeleteNote:
      'Данное действие невозможно отменить. После удаления подразделение нельзя будет назначить пользователю.',
    confirmExclude: 'Вы действительно хотите исключить из подразделения выбранного пользователя?',
    confirmExcludeNote:
      'Пользователя можно будет включить в подразделение с помощью кнопки «Добавить пользователя»',
    createGroup: 'Создание подразделения',
    deleteGroup: 'Удаление подразделения',
    editGroup: 'Редактирование подразделения',
    enterGroupName: 'Введите название подразделения',
    exclude: 'Исключить',
    excludeFromGroup: 'Исключить из подразделения',
    excludeUserFromGroup: 'Исключение пользователя из подразделения',
    groupName: 'Название подразделения',
    groups: 'Подразделения',
    selectUser: 'Выберите пользователя',
  },
  id: 'ID',
  imagesComparison: 'Сравнение фотографий',
  inProgress: 'В процессе обработки',
  include: 'Включить',
  invalidDate: 'Некорректная дата',
  isBanned: 'Заблокирован',
  lastname: 'Фамилия',
  list: 'Отобразить списком',
  login: 'Логин',
  managingRoles: {
    areYouSureDeleteRole: 'Вы действительно хотите удалить роль',
    choosePrivileges: 'Выберите привилегии',
    collapseAll: 'Свернуть всё',
    deleteRole: 'Удаление роли',
    enterRoleName: 'Введите название роли',
    expandAll: 'Развернуть всё',
    managingPrivs: 'Управление привилегиями',
    privileges: 'Привилегии',
    roleCreation: 'Создание роли',
    roleEdit: 'Редактирование роли',
    roleName: 'Название роли',
    searchPlaceholder: 'Введите привилегию',
  },
  messages: {
    audioModelDeleted: 'Голосовая модель успешно удалена',
    audioModelEdit: 'Голосовая модель успешно перестроена',
    audioUploaded: 'Аудио загружено',
    authError: 'Ошибка авторизации:',
    cardFoundInHotlist: 'Карточка «{{name}}» найдена в хотлисте!',
    cardsExported: 'Карточки отправлены на экспорт: {{ids}}',
    errorDeleteRole: 'Ошибка при удалении роли',
    faceModelDeleted: 'Лицевая модель успешно удалена',
    faceModelEdit: 'Лицевая модель успешно перестроена',
    failedCopyID: 'Не удалось скопировать ID',
    failedToDeleteFile: 'Не удалось удалить файл',
    fileDeletedSuccessful: 'Файл {{fileName}} успешно удален',
    fileUploaded: 'Файл успешно загружен',
    idCopied: 'ID успешно скопирован',
    invalidFileFormat: 'Неверный формат файла',
    openCard: 'Открыть карточку',
    photoUploaded: 'Фото успешно загружено',
    playerIsNotResponding: 'Плеер не отвечает',
    roleCreatedSuccessful: 'Роль успешно создана',
    rolesDeleted: 'Роли успешно удалены',
    roleUpdatedSuccessful: 'Роль успешно обновлена',
    serverIsNotResponding: 'Сервер не отвечает. Повторите запрос позже',
    userChangePasswordSuccessful: 'Пароль успешно изменён',
    userCreatedSuccessful: 'Пользователь успешно создан',
    userUpdatedSuccessful: 'Пользователь успешно обновлен',
    userWasAdded: 'Пользователь {{username}} добавлен в подразделение «{{groupName}}»',
    userWasExcluded: 'Пользователь {{username}} исключен из подразделения «{{groupName}}»',
    usersBlocked: 'Пользователи успешно заблокированы',
    usersUnblocked: 'Пользователи успешно разблокированы',
    usersWereAdded: 'Пользователи {{usernames}} добавлены в подразделение «{{groupName}}»',
    usersWereExcluded: 'Пользователи {{usernames}} исключены из подразделения «{{groupName}}»',
    groupCreated: 'Подразделение успешно создано',
    groupEdited: 'Подразделение успешно обновлено',
    groupDeleted: 'Подразделение успешно удалено',
    filesDeleted: 'Файлы успешно удалены',
    commentUpdated: 'Комментарий успешно обновлён',
    cardsDeleted: 'Карточки успешно удалены',
    deviceSettingsWereUpdated: 'Настройки устройства были успешно обновлены',
    deviceWasDeleted: 'Устройство успешно удалено',
    devicesWereDeleted: 'Устройства успешно удалены',
    privilegesWereUpdated: 'Привилегии были успешно обновлены',
    failedToStartRecording: 'Не удалось начать запись',
    errorCalculatingPureSpeech: 'Ошибка при подсчёте количества чистой речи',
    versionAlreadyExists: 'Дистрибутив с версией {{version}} уже загружен',
    distributionDeleted: 'Дистрибутив был успешно удалён',
  },
  middlename: 'Отчество',
  multipass: {
    cardId: 'ID карточки',
    addFacialModel: 'Добавить лицевую модель',
    addInCard: 'Добавить в карточку',
    addedBy: 'Кем добавлено',
    additionDate: 'Дата добавления',
    allPeriod: 'Весь период',
    audioDuration: 'Длительность записи',
    autoMarkup: 'Авторазметка',
    biometrics: 'Биометрия',
    birthDate: 'Дата рождения',
    calendar: 'Календарь',
    cardErrorCreated: 'Карточка не создана',
    cardExported: 'Карточка успешно экспортирована',
    cardExportedWithErrors: 'Карточка экспортирована с ошибками',
    cardSuccessfullyCreated: 'Карточка успешно создана',
    cardSuccessfullyEdited: 'Карточка успешно отредактирована',
    cardSuccessfullyExported: 'Карточка успешно экспортирована',
    cardWasChangedAfterExport: 'Карточка изменена после экспорта',
    closePlayer: 'Скрыть расширенный плеер',
    comment: 'Комментарий',
    completed: 'Загружено',
    createEntry: 'Создать карточку',
    createNewEntry: 'Создать новую карточку',
    createdDateTime: 'Дата и время добавления',
    createdTime: 'Дата загрузки',
    deleteFileModal: {
      textBody: 'Вы действительно хотите удалить запись',
      textButtonConfirm: 'Удалить',
      title: 'Удаление записи',
    },
    desktop: 'Рабочий стол',
    dictors: 'Дикторы',
    editedDateTime: 'Дата и время изменения',
    entries: 'Картотека',
    entryCreated: 'Карточка успешно создана',
    errorCardNotExported: 'Ошибка экспорта. Не удалось экспортировать карточку',
    errorWhenLoadingCard: 'Произошла ошибка при загрузке карточки',
    errorWhenLoadingTemplates: 'Произошла ошибка при загрузке шаблонов карточки',
    excluded: 'Исключено',
    extension: 'Расширение',
    fileDetails: 'Детали файла',
    fileList: 'Список файлов',
    fileName: 'Имя файла',
    fileProcessNotFound: 'Обработанный файл не найден',
    fileProcessingError:
      'При обработке файла произошла ошибка. Загрузите файл заново или свяжитесь с администратором',
    foundedDictors: 'Найденные дикторы',
    gender: 'Пол',
    generalInfo: 'Основная информация',
    isInHotList: 'Есть в горячем списке',
    infoAudio: 'Информация об аудиофайле',
    infoPhoto: 'Информация о фото',
    interactiveCard: 'Интерактивная карточка',
    manualMarkup: 'Ручная разметка',
    missingDataText: 'Загрузите аудиозапись, чтобы начать работу',
    missingDataTitle: 'Аудиозаписи',
    modelNotFound: 'Модель не найдена',
    modelQuality: 'Качество модели',
    excellentQuality: 'Отличное качество',
    goodQuality: 'Хорошее качество',
    badQuality: 'Плохое качество',
    nameEdit: 'Кем изменён',
    nameLoad: 'Кем добавлен',
    newEntry: 'Новая карточка',
    newEntryFrom: 'Новая от',
    noTemplatesFound: 'Не найдены шаблоны для создания карточки',
    noUploadedAudio: 'Нет загруженных аудиозаписей',
    noUploadedFiles: 'Нет загруженных файлов',
    openFullSize: 'Открыть в полном размере',
    closeFullscreen: 'Выйти из полноэкранного просмотра',
    openInNewTab: 'Открыть в новой вкладке',
    openPlayer: 'Показать расширенный плеер',
    passport: 'Паспорт',
    personalData: 'Данные',
    pureSpeechDuration: 'Длительность чистой речи',
    recommendedData: 'Рекомендуемые характеристики',
    resource: 'Источник',
    samples: 'Обработка файлов',
    selectFileTitle: 'Выберите запись, чтобы увидеть информацию о спикерах',
    selectedMarkup: 'Выбранная разметка',
    setAsMain: 'Сделать основной',
    setAsMainSpeaker: 'Сделать основным',
    setAsMainPhoto: 'Сделать основной',
    speechDuration: 'Длительность размеченной речи',
    status: 'Статус',
    toDisplayEntrySelectOneObject: 'Для отображения карточки выберите один объект',
    today: 'Сегодня',
    tooltipDuplicate: 'Дубликат',
    tooltipVerified: 'Завершено',
    totalDuration: 'Общая длительность',
    typeFile: 'Тип',
    uploadAudio: 'Чтобы построить модель голоса, загрузите аудиозапись',
    verifyFile: 'Завершить',
    yesterday: 'Вчера',
    fileProcessStatus: {
      [EFileProcessStatus.NEW]: 'В очереди',
      [EFileProcessStatus.INPROGRESS]: 'В обработке',
      [EFileProcessStatus.COMPLETED]: 'Завершен',
      [EFileProcessStatus.FAILED]: 'Ошибка',
      [EFileProcessStatus.DUPLICATE]: 'Дубликат',
    },
  },
  name: 'Имя',
  new: 'Новый',
  newPassword: 'Новый пароль',
  newPasswordConfirmation: 'Повторите новый пароль',
  password: 'Пароль',
  passwordConfirmation: 'Повторите пароль',
  player: {
    algorithm: 'Алгоритм',
    audioDuration: 'Длительность аудио',
    byteUnit: 'байт',
    clipFragmentsLength: 'Совокупная длительность клиппированных фрагментов',
    coe: 'СКО для значения реверберации',
    dBUnit: 'дБ',
    encodingType: 'Тип кодирования',
    fileSize: 'Размер файла',
    hzUnit: 'Гц',
    lowerBound: 'Нижняя частотная граница речевого сигнала',
    maxLengthNoClip: 'Максимальная длительность неклиппированных фрагментов',
    next: 'След.',
    noiseFragmentsLength: 'Совокупная длительность зашумленных фрагментов',
    prev: 'Пред.',
    quality: 'Качество',
    reverberation: 'Реверберация',
    reverberationMoments: 'Кол-во моментов реверберации',
    samplingRate: 'Частота дискретизации',
    segmentsLength: 'Длина выделенных сегментов',
    signalAndNoise: 'ОСШ',
    slowDown: 'Замедлить воспроизведение',
    speechDuration: 'Длительность речи',
    speedUp: 'Ускорить воспроизведение',
    upperBound: 'Верхняя частотная граница речевого сигнала',
    channelNumber: 'Канал {{channel}}',
  },
  privileges: {
    ADMINISTRATION: 'Администрирование',
    [PrivilegeCode.CREATE_CARDS]: {
      actions: 'Просмотр карточек по подразделению пользователя; | Создание карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Создание карточки',
    },
    [PrivilegeCode.CREATE_GROUPS]: {
      actions: 'Просмотр списка подразделений и их участников; | Создание нового подразделения',
      description: 'Возможные действия в разделе Администрирование / Подразделения:',
      title: 'Создание подразделения',
    },
    [PrivilegeCode.CREATE_ROLES]: {
      actions: 'Просмотр списка ролей и их привилегий; | Создание роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Создание роли',
    },
    [PrivilegeCode.CREATE_USER]: {
      actions: 'Просмотр списка пользователей; | Создание нового пользователя',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Создание пользователя',
    },
    [PrivilegeCode.DELETE_CARDS]: {
      actions: 'Просмотр карточек по подразделению пользователя; | Удаление карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Удаление карточки',
    },
    [PrivilegeCode.DELETE_GROUPS]: {
      actions: 'Просмотр списка подразделений и их участников; | Удаление подразделения',
      description: 'Возможные действия в разделе Администрирование / Подразделения:',
      title: 'Удаление подразделения',
    },
    [PrivilegeCode.DELETE_ROLES]: {
      actions: 'Просмотр списка ролей и их привилегий; | Удаление роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Удаление роли',
    },
    [PrivilegeCode.EDIT_CARDS]: {
      actions: 'Просмотр карточек по подразделению пользователя; | Редактирование карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Редактирование карточки',
    },
    [PrivilegeCode.EDIT_GROUPS]: {
      actions: 'Просмотр списка подразделений и их участников; | Редактирование подразделения',
      description: 'Возможные действия в разделе Администрирование / Подразделения:',
      title: 'Редактирование подразделения',
    },
    [PrivilegeCode.EDIT_ROLES]: {
      actions:
        'Просмотр списка ролей и их привилегий; | Редактирование роли; | Управление привилегиями - включение привилегий в роль и исключение привилегий из роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Редактирование роли',
    },
    [PrivilegeCode.EDIT_USER]: {
      actions:
        'Просмотр списка пользователей; | Редактирование пользователя; | Сброс пароля пользователя',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Редактирование пользователя',
    },
    ENROLLMENT: 'Картотека',
    [PrivilegeCode.EXPORT_CARDS]: {
      actions: 'Просмотр карточек по подразделению пользователя; | Экспорт карточек',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Экспорт карточки',
    },
    [PrivilegeCode.EXPORT_CARD_FILES]: {
      actions: 'Просмотр карточек по подразделению пользователя; | Экспорт файлов карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Экспорт файлов карточки',
    },
    FILE_MANAGEMENT: 'Управление картотекой',
    [PrivilegeCode.LOCK_UNLOCK_USER]: {
      actions:
        'Просмотр списка пользователей; | Блокировка пользователей; | Разблокировка пользователей',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Блокировка и разблокировка пользователя',
    },
    USER_ACCOUNT_MANAGEMENT: 'Управление учетными записями пользователей',
    [PrivilegeCode.USER_GROUP_AFFILIATION]: {
      actions:
        'Просмотр списка подразделений и их участников; | Добавление пользователя в подразделение; | Удаление пользователя из подразделения',
      description: 'Возможные действия в разделе Администрирование / Подразделения:',
      title: 'Добавление пользователя в подразделение и исключение из подразделения',
    },
    USER_GROUP_MANAGEMENT: 'Управление подразделениями пользователей',
    USER_ROLE_MANAGEMENT: 'Управление ролями пользователей',
    [PrivilegeCode.VIEW_ALL_CARDS]: {
      actions: 'Просмотр всех карточек',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Просмотр всей картотеки',
    },
    [PrivilegeCode.VIEW_CARDS]: {
      actions: 'Просмотр карточек по подразделению пользователя',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Просмотр картотеки',
    },
    [PrivilegeCode.VIEW_GROUPS]: {
      actions: 'Просмотр списка подразделений и их участников',
      description: 'Возможные действия в разделе Администрирование / Подразделения:',
      title: 'Просмотр списка подразделений',
    },
    [PrivilegeCode.VIEW_ROLES]: {
      actions: 'Просмотр списка ролей и их привилегий',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Просмотр списка ролей',
    },
    [PrivilegeCode.VIEW_USERS]: {
      actions: 'Просмотр списка пользователей',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Просмотр списка пользователей',
    },
    DEVICES: 'Устройства',
    DEVICE_MANAGEMENT: 'Управление устройствами',
    [PrivilegeCode.ACCESS_TO_DEVICES]: {
      actions:
        'Просмотр списка устройств; | Редактирование настроек устройства; | Удаление устройства; | Обновление ПО устройства; | Редактирование привилегий устройства; | Загрузка журнала работы устройства;',
      description: 'Возможные действия в разделе Устройства:',
      title: 'Доступ к устройствам',
    },
    reference: 'Справка',
  },
  portraitCharacteristics: {
    title: 'Портретные характеристики',
    NoDarkGlasses: 'Без темных очков',
    NoGlasses: 'Без очков',
    NoBeard: 'Без бороды',
    NoMustache: 'Без усов',
    NoSmile: 'Без улыбки',
    MouthClosed: 'Рот закрыт',
    LeftEyeOpen: 'Левый глаз открыт',
    RightEyeOpen: 'Правый глаз открыт',
    FrontalPoseDeviation: 'Наклон головы в бок',
    Exposure: 'Экспозиция',
    Sharpness: 'Резкость',
    GrayscaleDensity: 'Контраст',
    DeviationFromUniformLighting: 'Равномерное освещение',
    Hotspots: 'Нет засветов',
  },
  refresh: 'Обновить',
  repeatNewPassword: 'Повторите новый пароль',
  repeatPassword: 'Повторите пароль',
  resume: 'Возобновить',
  role: 'Роль',
  roleManagement: 'Управление ролями',
  roles: 'роли',
  save: 'Сохранить',
  saveAndClose: 'Сохранить и закрыть',
  saveToStartWithBioscanner: 'Сохраните работу для начала работы с BioScanner',
  searchQuery: 'Поисковые запросы',
  selectGroup: 'Выберите подразделение',
  selected: 'Выбрано',
  sets: 'Множества',
  sideFilterSelectPlaceholder: 'Выберите значение',
  sideFilterStringPlaceholder: 'Введите значение',
  speaker: 'Диктор',
  thisActionCannotBeUndone: 'Данное действие невозможно отменить',
  tile: 'Отобразить плиткой',
  unblock: 'Разблокировать',
  unblockUser: 'Разблокировать пользователя',
  upload: 'Загрузить',
  userCreation: 'Создание пользователя',
  userEdit: 'Редактирование пользователя',
  username: 'Имя пользователя',
  users: 'Пользователи',
  validation: {
    invalidFormat: 'Некорректный формат',
    maxCount: 'Макс. кол-во значений в поле {{count}}',
    maxLength: 'Макс. кол-во символов: {{count}}',
    minLength: 'Мин. кол-во символов: {{count}}',
    passwordsNotMatch: 'Пароли не совпадают',
    required: 'Обязательное поле',
    requiredOptions: 'Выберите одно из значений',
    shouldBeEmail: 'Введен некорректный адрес эл.почты',
  },
  youSureWantBlockUsers: 'Вы действительно хотите заблокировать пользователя {{name}}?',
  youSureWantUnblockUsers: 'Вы действительно хотите разблокировать пользователя {{name}}?',
  devices: {
    devices: 'Устройства',
    status: {
      [DeviceStatus.ONLINE]: 'В сети',
      [DeviceStatus.OFFLINE]: 'Отключен',
      [DeviceStatus.UPDATING]: 'Обновление',
      [DeviceStatus.ERROR]: 'Ошибка',
    },
    deviceName: 'Имя устройства',
    dateModified: 'Дата изменения',
    networkAddress: 'Сетевой адрес',
    softwareVersion: 'Версия ПО',
    department: 'Подразделение',
    dateRegistered: 'Дата регистрации',
    authorizationType: 'Тип авторизации на устройстве',
    authorizationTypes: {
      [AuthorizationType.QR_CODE]: 'QR-код',
      [AuthorizationType.WITHOUT_AUTHORIZATION]: 'Без авторизации',
    },
    personCardAccess: 'Доступ к карточке физ лица',
    personCardAccesses: {
      [AccessToPersonCard.WITHOUT_CREDENTIALS]: 'Без указания учетной записи',
      [AccessToPersonCard.WITH_CREDENTIALS]: 'С указанием учетной записи',
    },
    accessTypesDescription:
      'Настройка способа открытия карточки физлица в Мультипаспорте по кнопке с устройства',
    deviceId: 'ID устройства',
    blocked: 'Заблокирован',
    searchPlaceholder: 'Поиск по имени или ID устройства ',
    deviceSettings: 'Настройки устройства',
    exportLogs: 'Экспорт журнала работы устройства',
    export: 'Экспортировать',
    updateSoftware: 'Обновить ПО',
    softwareVersions: 'Версии ПО',
    selectSoftwareVersion: 'Выберите версию для обновления',
    devicesSelectedForUpdate: 'Выбранные устройства для обновления:',
    availableDistributions: 'Доступные дистрибутивы:',
    deviceWillBeUpdated: 'Устройство будет обновлено на версию {{version}}',
    deleteSoftwareVersion: 'Удаление версии ПО',
    confirmDeleteSoftwareVersion: 'Вы действительно хотите удалить дистрибутив {{version}}?',
    cantDeleteSelectedVersion: 'Нельзя удалить выбранную версию ПО',
    noDistributionsForUpdate: 'Нет дистрибутивов для обновлений',
    privilegesSettings: 'Привилегии для доступа без авторизации',
    deleteDevices: 'Удаление устройств',
    confirmDeleteOne: 'Вы действительно хотите удалить устройство?',
    confirmDeleteMultiple: 'Вы действительно хотите удалить выбранные устройства ({{count}})?',
    blockDevice: 'Заблокировать устройство',
    period: 'Период',
  },
  units: {
    byte: 'Б',
    kilobyte: 'КБ',
    megabyte: 'МБ',
  },
};
