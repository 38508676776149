import { CSSObject } from 'tss-react';

import { COLOR, VARIANT } from '@/Providers/ThemeProvider/types';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const buttonStyles: Record<string, Record<string, CSSObject>> = {
  [VARIANT.CONTAINED]: {
    [COLOR.PRIMARY]: {
      backgroundColor: appColors.bgPrimary,
      color: appColors.white,
      boxShadow: '0 2px 4px rgba(100, 89, 235, 0.24)',
      '&:hover, &:active': {
        boxShadow: '0 2px 4px rgba(100, 89, 235, 0.24)',
        backgroundColor: appColors.bgPrimaryHover,
        color: appColors.white,
      },
      '&:disabled': {
        backgroundColor: appColors.bgPrimaryDisabled,
        color: appColors.white,
      },
    },
    [COLOR.SECONDARY]: {
      backgroundColor: appColors.white,
      color: appColors.black,
      boxShadow: '0 2px 4px rgba(135, 135, 135, 0.16)',
      '&:hover, &:active': {
        boxShadow: '0 2px 4px rgba(100, 89, 235, 0.24)',
        // backgroundColor: darken(appColors.white, 0.07),
        color: appColors.black,
      },
    },
  },
  [VARIANT.TEXT]: {
    [COLOR.PRIMARY]: {
      backgroundColor: 'transparent',
      color: appColors.bgPrimary,
      padding: 0,
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
    },
  },
  [VARIANT.OUTLINED]: {
    [COLOR.PRIMARY]: {
      backgroundColor: appColors.white,
      color: appColors.black,
      boxShadow: '0px 4px 8px 0px rgba(69, 74, 89, 0.16)',
      border: '1px solid rgba(69, 74, 89, 0.24)',
    },
  },
};

export const defaultButtonStyles: CSSObject = {
  height: 'fit-content',
  fontSize: 12,
  lineHeight: '16px',
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 700,
  boxShadow: 'none',
};
