import { CSSObject } from 'tss-react';

import { COLOR } from '@/Providers/ThemeProvider/types';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const radioStyles: Record<string, CSSObject> = {
  [COLOR.PRIMARY]: {
    '&.Mui-checked': {
      color: appColors.success,
    },
  },
  [COLOR.WARNING]: {
    color: appColors.warning,

    '&.Mui-checked': {
      color: appColors.warning,
    },
  },
  [COLOR.SECONDARY]: {
    backgroundColor: appColors.darkGrey,
    color: '#FFFFFF',
  },
  [COLOR.ERROR]: {
    color: appColors.error,

    '&.Mui-checked': {
      color: appColors.error,
    },
  },
  [COLOR.DEFAULT]: {},
};

export const defaultRadioStyles: CSSObject = {};
