import { action, makeObservable, observable } from 'mobx';

import { Face, FaceModel, Image } from '@/types';

class ImageCompareStore {
  constructor() {
    makeObservable(this);
  }

  @observable firstImage: Image | null = null;
  @observable secondImage: Image | null = null;
  @observable targetSelectedFace: Face | null = null;
  @observable etalonSelectedFace: Face | null = null;
  @observable isFirstImageComparisonsLoaded = false;
  @observable isSecondImageComparisonsLoaded = false;

  @action setImage = (image: Image | null, areaNumber: number) => {
    if (areaNumber === 1) {
      this.firstImage = image;
    } else {
      this.secondImage = image;
    }
  };

  @action setTargetSelectedFace = (face: Face | null) => {
    // если в строке аватаров выбрали фото
    if (face && !face.modelUri) {
      const areaNumber = face.areaNumber;
      const index = face.index;

      if (areaNumber === 1 && this.firstImage?.faces && index) {
        const foundFace = [...this.firstImage?.faces][index];
        this.targetSelectedFace = {
          index,
          areaNumber: face.areaNumber,
          modelUri: foundFace.modelUri,
          normalizedImageUri: foundFace.normalizedImageUri,
          area: foundFace.area,
          comparisons: foundFace.comparisons,
        };
      } else if (areaNumber === 2 && this.secondImage?.faces && index) {
        const foundFace = [...this.secondImage?.faces][index];
        this.targetSelectedFace = {
          index,
          areaNumber: face.areaNumber,
          modelUri: foundFace.modelUri,
          normalizedImageUri: foundFace.normalizedImageUri,
          area: foundFace.area,
          comparisons: foundFace.comparisons,
        };
      }
    } else {
      this.targetSelectedFace = face;
    }
  };

  @action setEtalonSelectedFace = (face: Face | null) => {
    this.etalonSelectedFace = face;
  };

  @action initImage = (url: string, hash: string, models: FaceModel[], areaNumber: number) => {
    const faces: Face[] = [];
    models.forEach((model) => {
      const face: Face = {
        area: model.area,
        areaNumber,
        modelUri: model.model,
        normalizedImageUri: model.normalized_image_uri,
      };
      faces.push(face);
    });
    const image: Image = {
      url,
      hash,
      faces,
    };
    this.setImage(image, areaNumber);
  };

  @action setFaceComparisons = (faceModelUri: string, comparisons: Face[], areaNumber: number) => {
    if (areaNumber === 1) {
      this.firstImage?.faces.forEach((face) => {
        if (face.modelUri === faceModelUri) {
          face.comparisons = comparisons;

          return;
        }
      });
    } else {
      this.secondImage?.faces.forEach((face) => {
        if (face.modelUri === faceModelUri) {
          face.comparisons = comparisons;

          return;
        }
      });
    }
  };

  @action setIsFirstImageComparisonsLoaded = (state: boolean) => {
    this.isFirstImageComparisonsLoaded = state;
  };

  @action setIsSecondImageComparisonsLoaded = (state: boolean) => {
    this.isSecondImageComparisonsLoaded = state;
  };

  clearImageComparisons = (areaNumber: number) => {
    if (areaNumber === 1) {
      this.secondImage?.faces.forEach((face) => (face.comparisons = undefined));
    } else {
      this.firstImage?.faces.forEach((face) => (face.comparisons = undefined));
    }
    this.setIsFirstImageComparisonsLoaded(false);
    this.setIsSecondImageComparisonsLoaded(false);
  };

  clearLoadedImagesData = () => {
    this.setImage(null, 1);
    this.setImage(null, 2);
    this.setTargetSelectedFace(null);
    this.setEtalonSelectedFace(null);
    this.setIsFirstImageComparisonsLoaded(false);
    this.setIsSecondImageComparisonsLoaded(false);
  };
}

export default ImageCompareStore;
