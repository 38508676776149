import { observer } from 'mobx-react';
import { useEffect, useState, lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './i18n/config';
import { LicenseInfo } from '@mui/x-license-pro';
import '@/lib/yup';
import './App.scss';
import RequireAuth from './Providers/AuthProvider/RequireAuth';
import { useDataStore } from './Providers/StoreProvider';
import { EAdministrationRoute, EMultipassRoute, ROUTES } from './types';
import { MenuDrawer } from '@/components/MenuDrawer';
import rootApiService from '@/services/root.api.service';
import { useSnackbar } from 'notistack';
import { RequirePermission } from './Providers/PermissionProvider/RequirePermission';
import { AppLoader } from './components/loaders/AppLoader';
import { useInitAppSse } from './hooks/useInitAppSse';
import { useUserPermissionsContext } from './hooks/useUserPermissionsContext';
import { useTranslation } from 'react-i18next';

const AuthPage = lazy(() => import('./pages/AuthPage'));
const Administration = lazy(() =>
  import('./modules').then((module) => ({ default: module.Administration }))
);
const Multipass = lazy(() => import('./modules').then((module) => ({ default: module.Multipass })));
const Users = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Users }))
);
const Roles = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Roles }))
);
const Groups = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Groups }))
);
const CardCreation = lazy(() =>
  import('./modules/multipass').then((module) => ({ default: module.CardCreation }))
);
const FilingCabinet = lazy(() =>
  import('./modules/multipass').then((module) => ({ default: module.FilingCabinet }))
);
const Devices = lazy(() =>
  import('./modules/devices').then((module) => ({ default: module.Devices }))
);

LicenseInfo.setLicenseKey(
  '9603c734009c51dd5602b3210062b735Tz03OTEyOSxFPTE3MzIxODMzODEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const App = () => {
  const { userStore, constantsStore, rootMediaDevicesStore, sseStore } = useDataStore();
  const { defaultRoute } = useUserPermissionsContext();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    constantsStore.init(enqueueSnackbar);
  }, [constantsStore, enqueueSnackbar]);

  useEffect(() => {
    const initializeApp = () => {
      try {
        rootApiService.init(userStore, rootMediaDevicesStore, sseStore);
      } catch (error) {
        console.error(error);
      } finally {
        setIsInitialized(true);
      }
    };

    void initializeApp();
  }, [constantsStore, userStore, rootMediaDevicesStore, sseStore]);

  useEffect(() => {
    document.title = t('appName');
  }, [t]);

  useInitAppSse();

  if (!isInitialized) {
    return <AppLoader />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path={ROUTES.AUTH} element={<AuthPage />} />
        <Route
          path={ROUTES.ADMINISTRATION}
          element={
            <MenuDrawer>
              <RequireAuth>
                <RequirePermission>
                  <Administration />
                </RequirePermission>
              </RequireAuth>
            </MenuDrawer>
          }
        >
          <Route path='' element={<Navigate to={ROUTES.ADMINISTRATION_USERS} replace={true} />} />
          <Route path={EAdministrationRoute.users} element={<Users />} />
          <Route path={EAdministrationRoute.roles} element={<Roles />} />
          <Route path={EAdministrationRoute.groups} element={<Groups />} />
        </Route>
        <Route
          path={ROUTES.MULTIPASS}
          element={
            <MenuDrawer>
              <RequireAuth>
                <RequirePermission>
                  <Multipass />
                </RequirePermission>
              </RequireAuth>
            </MenuDrawer>
          }
        >
          {/* <Route path='' element={<Navigate to={ROUTES.MULTIPASS_LOAD_FILE} replace={true} />} />
          <Route
            path={EMultipassRoute.loadFile}
            element={
              <RequirePermission>
                <LoadFile />
              </RequirePermission>
            }
          />
          <Route
            path={EMultipassRoute.interactiveCard}
            element={
              <RequirePermission>
                <InteractiveCard />
              </RequirePermission>
            }
          /> */}
          <Route
            path={EMultipassRoute.filingCabinet}
            element={
              <RequirePermission>
                <FilingCabinet />
              </RequirePermission>
            }
          />
          <Route
            path={EMultipassRoute.cardCreation}
            element={
              <RequirePermission>
                <CardCreation />
              </RequirePermission>
            }
          />
          <Route
            path={EMultipassRoute.cardEdit}
            element={
              <RequirePermission>
                <CardCreation />
              </RequirePermission>
            }
          />
        </Route>
        <Route
          path={ROUTES.DEVICES}
          element={
            <MenuDrawer>
              <RequireAuth>
                <RequirePermission>
                  <Devices />
                </RequirePermission>
              </RequireAuth>
            </MenuDrawer>
          }
        />
        <Route
          path='*'
          element={
            <RequireAuth>
              <Navigate to={defaultRoute || ROUTES.AUTH} replace={true} />
            </RequireAuth>
          }
        />
      </Route>
    )
  );

  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  );
};

export default observer(App);
