import { CSSObject } from 'tss-react';

import { appColors } from '@/Providers/ThemeProvider/colors';
import { COLOR } from '@/Providers/ThemeProvider/types';

export const defaultToolbarStyles: CSSObject = {
  borderRadius: '6px 6px 0px 0px',
  borderBottom: '1px solid #D0D0D0',
};

export const toolbarStyles: Record<string, CSSObject> = {
  [COLOR.PRIMARY]: {
    backgroundColor: appColors.primary,
    color: '#FFFFFF',
  },
};
