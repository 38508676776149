import { ApiError } from './ApiError';

export class FileProcessingError extends Error {
  message: string;
  apiError?: ApiError;

  constructor(message: string, apiError?: ApiError) {
    super();
    this.message = message;
    this.apiError = apiError;
  }
}
