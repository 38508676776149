export const appColors = {
  grey: '#cfd8dc',
  grey100: 'rgba(69, 74, 89, 0.08)',
  grey200: '#CDCDCD',
  grey700: '#aeaeae',
  grey800: '#454A59CC',
  grey900: '#454A59',
  darkGrey: '#999',

  black: '#212121',
  black200: 'rgba(0, 0, 0, 0.16)',
  black500: 'rgba(0, 0, 0, 0.4)',
  black600: 'rgba(0, 0, 0, 0.64)',
  black900: 'rgb(0, 0, 0)',

  darkLightGrey: 'hsla(0, 0%, 100%, .322)',

  ligthGrey: '#eceff1cc',
  ligthLightGrey: '#eceff166',
  transparent: 'transparent',

  steal200: '#454A5929',
  steal300: '#454A5952',

  teal300: 'rgba(1, 188, 213, 0.4)',
  teal400: 'rgba(0, 108, 122, 0.4)',

  white: '#ffffff',
  white200: 'rgba(255, 255, 255, 0.16)',
  white300: 'rgba(255, 255, 255, 0.24)',
  white600: 'rgba(255,255,255,.64)',

  red: '#D03801',
  red700: '#ff8882',
  red900: '#E22B02',

  indigo600: '#4562ff',

  green: '#1AAE77',
  green900: '#2B9919',
  lightGreen: '#28A970',

  blue: '#1976d2',
  blue900: 'rgba(111, 100, 246, 1)',
  yellow: '#ffa000',
  pink: '#e91e63',
  bgPrimary: '#6F64F6',
  bgPrimaryHover: '#6459EB',
  bgPrimaryDisabled: '#6F64F63D',
  orange: '#ff5722',
  orange500: 'rgb(255, 166, 62)',
  orange900: '#F6A300',
  cyan: '#01bcd5',
  pang: '#cafced',

  primary: '#27303F',
  primaryLight: '#00838f',

  secondary: '#F7F7F7',

  success: '#008697',

  warning: '#C08615',

  error: '#D03801',

  appToolbarBackgroundColor: '#0b2b48',
  gridCellBorderColor: 'lightGrey',
  darkThemeBorder: 'hsla(0,0%,100%,.161)',
  darkThemeBackground: '#2a2b2f',

  toolbarBackgroundColor: 'white',
  tabsBackgroundColor: '#eceff166',
  headersBackgroundColor: '#eceff166',

  selectedItem: '#EDFAFC',

  // TODO: change default background color to white for all pages
  mainBackground: 'rgba(191,211,225,.7)',

  titleYellow: '#e8f5e9',

  // warm player colors
  warm1: 'rgba(249, 153, 150, 0.6)',
  warm2: 'rgba(243, 152, 100, 0.6)',
  warm3: 'rgba(243, 186, 100, 0.6)',
  warm4: 'rgba(240, 225, 92, 0.6)',
  warm5: 'rgba(203, 245, 32, 0.6)',

  // cold player colors
  cold1: 'rgba(34, 195, 165, 0.6)',
  cold2: 'rgba(9, 225, 225, 0.6)',
  cold3: 'rgba(9, 175, 225, 0.6)',
  cold4: 'rgba(25, 82, 245, 0.6)',
  cold5: 'rgba(123, 46, 224, 0.6)',

  // warm player colors
  warm1NoTransparency: 'rgba(249, 153, 150)',
  warm2NoTransparency: 'rgba(243, 152, 100)',
  warm3NoTransparency: 'rgba(243, 186, 100)',
  warm4NoTransparency: 'rgba(240, 225, 92)',
  warm5NoTransparency: 'rgba(203, 245, 32)',

  // cold player colors
  cold1NoTransparency: 'rgba(34, 195, 165)',
  cold2NoTransparency: 'rgba(9, 225, 225)',
  cold3NoTransparency: 'rgba(9, 175, 225)',
  cold4NoTransparency: 'rgba(25, 82, 245)',
  cold5NoTransparency: 'rgba(123, 46, 224)',
};
