import { action, makeAutoObservable, observable } from 'mobx';

export enum CounterFilter {
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  ALL = 'ALL',
}

class FilterStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable queryFilter = {
    counter: CounterFilter.ALL,
  };

  @observable sqFilter = {
    counter: CounterFilter.ALL,
  };

  @action setQueryFilter = (counter: CounterFilter) => {
    this.queryFilter.counter = counter;
  };

  @action setSqFilter = (counter: CounterFilter) => {
    this.sqFilter.counter = counter;
  };
}

export default FilterStore;
