import { action, makeObservable, observable } from 'mobx';

import { Process } from '@/types';
import { IAllProcessesResponse, IProcessStatusResponse } from '@/apiTypes';

class ExportModel {
  @observable processes: Process[] = [];

  constructor() {
    makeObservable(this);
  }

  @action setProcessState = (state: IProcessStatusResponse) => {
    const { id, status, progress, result, error } = state;
    this.processes = this.processes.map((process) => {
      if (process.id === id) {
        process.status = status;
        process.progress = progress;
        process.result = result;
        process.error = error;
      }

      return process;
    });
  };

  @action setAllProcesses = (allProcesses: IAllProcessesResponse) => {
    this.processes = allProcesses.processes;
  };

  @action deleteProcessFromStore = (processId: number) => {
    this.processes = this.processes.filter((process) => process.id !== processId);
  };
}

export default ExportModel;
