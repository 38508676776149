import { UserPermissionsContext } from '@/contexts/UserPermissionsContext';
import { useContext } from 'react';

export const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);

  if (!context) throw new Error('Provide user permissions context');

  return context;
};
