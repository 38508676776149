export function packDate(
  day: string | undefined,
  month: string | undefined,
  year: string | undefined
) {
  const twoSign = (s: string | undefined) =>
    s === undefined || s === 'undefined' ? '—' : s.length === 1 ? `0${s}` : s;
  const fixYear = (s: string | undefined) => (s === undefined || s === 'undefined' ? '—' : s);

  return `${twoSign(day)}.${twoSign(month)}.${fixYear(year)}`;
}
