import { CSSObject } from 'tss-react';
import { linearProgressClasses } from '@mui/material/LinearProgress';

import { COLOR } from '@/Providers/ThemeProvider/types';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const linerStyles: Record<string, CSSObject> = {
  [COLOR.PRIMARY]: {
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: appColors.ligthGrey,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: appColors.success,
    },
  },
  [COLOR.WARNING]: {
    backgroundColor: appColors.warning,
    color: '#FFFFFF',
  },
  [COLOR.SECONDARY]: {
    backgroundColor: appColors.darkGrey,
    color: '#FFFFFF',
  },
  [COLOR.ERROR]: {
    backgroundColor: appColors.error,
    color: '#FFFFFF',
  },
  [COLOR.DEFAULT]: {},
};

export const defaultLinerStyles: CSSObject = {};
