import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import searchQueryService from '../../../services/searchQuery.service';

import { ISearchQueryError, ISmallError, ProgressStages, SnackbarActionType } from '@/types';
import { ESwitch } from '@/stores/ReportTargetsEtalons/types';

import { TargetsEtalonsStore } from '@/stores/ReportTargetsEtalons';
import { CounterFilter } from '@/stores/filter.model';
import { ISearchQuery } from '@/apiTypes';

export class SearchQueryListStore {
  @observable private _gridApiRef: GridApiPro | undefined = undefined;
  @observable _searchQueryList: ISearchQuery[] = [];
  @observable isLoad = false;
  @observable selectedId?: number;
  @observable seekString = '';
  @observable gridSortModel?: GridSortModel;
  @observable gridFilterModel?: GridFilterModel;
  private snackbarAction?: SnackbarActionType;
  private _noBackendMode = true;
  private _filerPanelVisible = false;

  constructor() {
    makeObservable(this);
  }

  @action clear() {
    this._gridApiRef = undefined;
    this._searchQueryList = [];
    this.isLoad = false;
    this.selectedId = undefined;
    this.snackbarAction = undefined;
    this._noBackendMode = true;
    this._filerPanelVisible = false;
  }

  @action setGridSortModel(val: GridSortModel) {
    this.gridSortModel = val;
  }

  @action setGridFilterModel(val: GridFilterModel) {
    this.gridFilterModel = val;
  }

  @action setFilterPanelVisibleFlag(value: boolean) {
    this._filerPanelVisible = value;
  }

  public toggleFilterPanel(): void {
    if (this._gridApiRef) {
      if (this._filerPanelVisible) {
        this.setFilterPanelVisibleFlag(!this._filerPanelVisible);
        this._gridApiRef.hideFilterPanel();
      } else {
        this.setFilterPanelVisibleFlag(!this._filerPanelVisible);
        this._gridApiRef.showFilterPanel();
      }
    } else {
      console.log('ref of grid for filter not ready');
    }
  }

  @computed get searchQueryList(): ISearchQuery[] {
    const seekString = this.seekString.trim().toUpperCase();
    if (seekString === '') {
      return this._searchQueryList;
    } else {
      return this._searchQueryList.filter((req) => req.job_name.toUpperCase().includes(seekString));
    }
  }

  @action init(snackbarAction: SnackbarActionType) {
    this.snackbarAction = snackbarAction;
    this.seekString = '';
  }

  get selectedItem(): ISearchQuery | undefined {
    return this.selectedId === undefined
      ? undefined
      : this.searchQueryList.find((req) => req.job_id === this.selectedId);
  }

  @action switchItem(forward: ESwitch) {
    const len = this.searchQueryList.length;
    if (this.selectedId === undefined || this.searchQueryList.length < 2) return;

    const oldIndex = this.searchQueryList.findIndex((req) => req.job_id === this.selectedId);
    let newIndex: number;
    if (forward === ESwitch.FORWARD) {
      newIndex = oldIndex + 1;
      newIndex = newIndex < len ? newIndex : 0;
    } else {
      newIndex = oldIndex - 1;
      newIndex = newIndex < 0 ? len - 1 : newIndex;
    }

    this.setSelect(this.searchQueryList[newIndex].job_id);

    if (this._gridApiRef) {
      this._gridApiRef.scrollToIndexes({
        colIndex: 0,
        rowIndex: newIndex,
      });
    }
  }

  @computed
  get selectedItemStatus(): ProgressStages | undefined {
    if (!this.searchQueryList || this.selectedId === undefined) {
      return undefined;
    }

    const selected = this.searchQueryList.find((req) => req.job_id === this.selectedId);

    return selected ? selected.job_status : undefined;
  }

  getGridApiRef(): GridApiPro | undefined {
    return this._gridApiRef;
  }

  @action setGridApiRef(ref: GridApiPro) {
    this._gridApiRef = ref;
  }

  @action setQueryTableLoadStatus(isLoadDone: boolean) {
    this.isLoad = isLoadDone;
  }

  @action setSelect = (id: number | undefined) => {
    if (this.selectedId !== id) {
      this.selectedId = id;
      const status = this.selectedItemStatus;
      if (id !== undefined && status && status === ProgressStages.COMPLETE) {
        void TargetsEtalonsStore.init(id, this.snackbarAction);
      }
    }
  };

  async deleteSelected(counter: CounterFilter) {
    if (this.snackbarAction) {
      if (this.selectedId !== undefined) {
        if (this._noBackendMode) {
          const ind = this._searchQueryList.findIndex((r) => r.job_id === this.selectedId);
          this.setSelect(undefined);
          runInAction(() => {
            this._searchQueryList.splice(ind, 1);
          });
        } else {
          await searchQueryService.deleteSearchQuery(this.selectedId, this.snackbarAction);
          this.setSelect(undefined);
          await this.load(counter);
        }
      }
    } else {
      console.log('SearchQueryListStore not ready');
    }
  }

  async cancelSearchQuery(id: number, counter: CounterFilter) {
    if (this.snackbarAction) {
      if (this._noBackendMode) {
        const canceledSearchQuery = this._searchQueryList.find((r) => r.job_id === id);
        if (canceledSearchQuery) {
          canceledSearchQuery.job_status = ProgressStages.CANCELED;
        }
      } else {
        await searchQueryService.cancelSearchQuery(id, this.snackbarAction);
        this.setSelect(undefined);
        await this.load(counter);
        this.setSelect(id);
      }
    } else {
      console.log('SearchQueryListStore not ready');
    }
  }

  async getSearchQueryInfo(id: number) {
    if (this.snackbarAction) {
      return await searchQueryService.getSearchQueryInfo(id, this.snackbarAction);
    } else {
      console.log('SearchQueryListStore not ready');

      return {};
    }
  }

  async getSearchQueryFiles(id: number) {
    if (this.snackbarAction) {
      return await searchQueryService.getSearchQueryFiles(id, this.snackbarAction);
    } else {
      console.log('SearchQueryListStore not ready');

      return;
    }
  }

  async getSearchQuery(id: number) {
    if (this.snackbarAction) {
      return await searchQueryService.getSearchQuery(id, this.snackbarAction);
    } else {
      console.log('SearchQueryListStore not ready');
    }
  }

  async getBigError(): Promise<ISearchQueryError> {
    const item = this.selectedItem;
    if (!item || item.job_status !== ProgressStages.ERROR) return { code: '', description: '' };
    if (!item.error) {
      const searchQuery = await this.getSearchQuery(item.job_id);

      if (searchQuery) {
        item.error = searchQuery.error;
      }

      return searchQuery?.error ?? { code: '', description: '' };
    } else {
      return item.error;
    }
  }

  async getSmallErrors(): Promise<ISmallError[]> {
    const item = this.selectedItem;
    if (!item || !item.error_size) return [];

    if (this.snackbarAction) {
      const result = await searchQueryService.getSearchQuerySmallErrors(
        item.job_id,
        this.snackbarAction
      );

      return result ? result.errors : [];
    } else {
      console.log('SearchQueryListStore not ready');

      return [];
    }
  }

  @action setSearchQueryList(list: ISearchQuery[]) {
    this._searchQueryList = list;
  }

  async load(counter: CounterFilter) {
    if (this.snackbarAction) {
      this.setQueryTableLoadStatus(false);
      try {
        const list = await searchQueryService.getSearchQueryList(counter, this.snackbarAction);
        this.setSearchQueryList(list);
      } catch {
        this.setSearchQueryList([]);
      }
      this.setQueryTableLoadStatus(true);
    } else {
      console.log('SearchQueryListStore not ready');
    }
  }
}
