import { CSSObject } from 'tss-react';

import { COLOR, VARIANT } from '@/Providers/ThemeProvider/types';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const chipStyles: Record<string, Record<string, CSSObject>> = {
  [VARIANT.FILLED]: {
    [COLOR.PRIMARY]: {
      backgroundColor: appColors.success,
      color: '#FFFFFF',
    },
    [COLOR.WARNING]: {
      backgroundColor: appColors.warning,
      color: '#FFFFFF',
    },
    [COLOR.SECONDARY]: {
      backgroundColor: appColors.darkGrey,
      color: '#FFFFFF',
    },
    [COLOR.ERROR]: {
      backgroundColor: appColors.error,
      color: '#FFFFFF',
    },
    [COLOR.DEFAULT]: {},
  },
};

export const defaultChipStyles: CSSObject = {
  borderRadius: '3px',
  padding: '10px 6px',
};
