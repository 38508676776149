import ImageCompareStore from './ImageCompareStore';

export default class ImageCompareRootStore {
  compareImagesStore: ImageCompareStore;

  constructor() {
    this.compareImagesStore = new ImageCompareStore();
  }

  clear() {
    this.compareImagesStore.clearLoadedImagesData();
  }
}
