import languageSerivce from '@/services/language.serivce';
import { ROUTES } from '@/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonDropdownList,
  ButtonStyle,
  ListItems,
  ListItemType,
  SelectFieldValue,
} from 'stc-ui-kit';

export function LanguageSelector() {
  const [languages, setLanguages] = useState<string[]>([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const initSelector = async () => {
      const defaultLanguage = await languageSerivce.getCurrent();
      if (defaultLanguage) {
        void i18n.changeLanguage(defaultLanguage);
      }

      const languages = await languageSerivce.getAll();

      if (languages) {
        setLanguages(languages);
      }
    };

    void initSelector();
  }, [i18n]);

  const setLanguage = (value: SelectFieldValue) => {
    if (!!value && languages.includes(value)) {
      void i18n.changeLanguage(value);

      if (window.location.pathname !== ROUTES.AUTH) {
        void languageSerivce.setLanguage({ language: value });
      }
    }
  };

  const items: ListItems = [
    (languages || []).map((lang) => ({
      id: lang,
      name: lang.toUpperCase(),
      type: ListItemType.Simple,
      onClick: () => setLanguage(lang),
    })),
  ];

  return (
    <ButtonDropdownList
      items={items}
      label={i18n.language.toUpperCase()}
      buttonStyle={ButtonStyle.Secondary}
      data-testid=''
    />
  );
}
