import { useSnackbar, SnackbarContent } from 'notistack';
import React, { forwardRef, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

import styles from './Snackbar.module.scss';

interface IProps {
  id: number;
  message: string;
  variant: string;
}

export const Snackbar = forwardRef<HTMLDivElement, IProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const isError = props.variant === 'error';

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Card className={styles.card}>
        <CardActions>
          {isError ? (
            <ErrorIcon className={styles.errorIcon} />
          ) : (
            <InfoIcon className={styles.infoIcon} />
          )}
          <Typography className={styles.text}>{props.message}</Typography>
          <IconButton size="small" onClick={handleDismiss}>
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});
// https://notistack.com/features/basic#dismiss-programmatically
// отображение сообщения о потере соединения до восстановления соединения.
// Может пригодиться для https://jira.speechpro.com/browse/TFTR-1108
