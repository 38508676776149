import { ECardExportStatus } from '@/types/card';
import { AccessToPersonCard, AuthorizationType, DeviceStatus } from '@/types/device';
import { EFileProcessStatus } from '@/types/file';
import { PrivilegeCode } from '@/types/role';

export const en = {
  appCreatorInfo: 'Speech Technology Center Limited. All rights reserved.',
  files: 'Files',
  tasks: 'Tasks',
  searchByFileName: 'Search by file name',
  appInfo: 'Biometric sampling software',
  appName: 'Sampler',
  fileDialog: {
    prepareFilesForUploading: 'Prepare audio for uploading to GridID',
    title: 'File upload',
    dragFileOrPress: 'Drag files here or press',
    invalidFileExtention:
      'File {{name}} has unsupported file type {{extention}}. File was not attached.',
    deleteCompletedTasks: 'Delete all finished tasks',
    deleteTask: 'Delete task',
    deleteTaskConfirm: 'Are you sure you want to delete task «{{name}}»?',
    positionInQueue: 'Position in queue',
  },
  add: 'Add',
  addUser: 'Add user',
  admin: 'admin',
  administration: 'Administration',
  unknown: 'Unknown',
  attachedFileStatus: {
    [EFileProcessStatus.NEW]: 'New',
    [EFileProcessStatus.INPROGRESS]: 'In progress',
    [EFileProcessStatus.COMPLETED]: 'Completed',
    [EFileProcessStatus.FAILED]: 'Failed',
    [EFileProcessStatus.DUPLICATE]: 'Duplicate',
  },
  attachedFiles: 'Attached files',
  auth: {
    confirmNewPassword: 'Confirm new password',
    createNewPassword: 'Create new password',
    enter: 'Enter',
    enterNewPassword: 'Enter new password',
    newPassword: 'New password',
    newPasswordConfirmation: 'Confirm new password',
    title: 'Sign in to GridID Sampler',
    user: 'User',
    welcome: 'Welcome!',
    youDontHavePermissions: 'You do not have enough permissions',
  },
  back: 'Back',
  yes: 'Yes',
  no: 'No',
  all: 'All',
  apply: 'Apply',
  block: 'Block',
  stop: 'Stop',
  from: 'from',
  warning: 'Warning',
  select: 'Select',
  blockUser: 'Block user',
  blockYourself: 'Blocking your own account is prohibited',
  cancel: 'Cancel',
  cards: {
    areYouShureDirDeleteOne: 'Do you really want to delete the selected entry?',
    areYouShureDirDeleteOneSeveral:
      'Do you really want to delete the ({{count}}) selected entries?',
    biometricModels: 'Biometric models',
    changed: 'Modified',
    changedBy: 'Updated by',
    clear: 'Clear',
    closeCardTitle: 'Close entry',
    created: 'Created',
    createdBy: 'Created by',
    createdCard: 'New entry',
    dateOfBirth: 'Date of birth',
    deleteAddedInCardAudio: 'Do you really want to delete the selected audio file?',
    deleteAddedInCardAudios: 'Do you really want to delete the selected audio files?',
    deleteAddedInCardPhoto: 'Do you really want to delete the selected image?',
    deleteAddedInCardPhotos: 'Do you really want to delete the selected images?',
    deleteAudioTitle: 'Delete audio file',
    deleteAudiosTitle: 'Delete audio files',
    deleteCard: 'Delete entry+B88',
    deleteDocumentConfirmation: 'Do you really want to delete the selected document?',
    deleteDocumentTitle: 'Delete document',
    deleteDocumentWithMainPhoto:
      'Do you really want to delete the selected document and face model?',
    deleteDocumentsConfirmation: 'Do you really want to delete the selected documents?',
    deleteDocumentsTitle: 'Delete documents',
    deleteFileConfirmation: 'Do you really want to delete the selected file?',
    deleteFileTitle: 'Delete file',
    deleteFilesConfirmation: 'Do you really want to delete the selected files?',
    deleteFilesTitle: 'Delete files',
    deleteMainPhotoDesc: 'Do you really want to delete the image and face model?',
    deletePhotoTitle: 'Delete image',
    deletePhotosTitle: 'Delete images',
    description: 'Description',
    documentAvailabilityIndicator: 'Document availability indicator',
    documents: 'Documents',
    export: 'Export',
    exportGridStatus: 'GridID export status',
    exportStatus: {
      [ECardExportStatus.NEW]: 'New',
      [ECardExportStatus.NEED_EXPORT]: 'Created',
      [ECardExportStatus.EXPORTED]: 'Exported',
    },
    face: 'Face',
    faceModel: 'Face Model',
    fullname: 'Name',
    gender: 'Gender',
    lastExported: 'Last exported',
    models: 'Models',
    name: 'Name',
    noItemsAvailable: 'No items available',
    noItemsToShow: 'No items to show',
    noModel: 'Mark as No Biometrics',
    noUploadedImages: 'No images uploaded',
    personId: 'Person ID',
    search: 'Type some text here',
    source: 'Source',
    statusCreated: 'Created',
    statusNew: 'New',
    succesCreateCard: 'Entry successfully created',
    succesDeletedСard: 'Entry successfully deleted',
    title: 'Entries',
    type: 'Type',
    unsavedChangesConfirmation:
      'Entry contains unsaved changes. Do you really want to close entry?',
    uploadImageTobuildFaceModel: 'Upload image to create face model',
    voice: 'Voice',
  },
  interactiveMode: {
    deviceRecording: 'Recording from devices',
    startRecording: 'Start recording',
    stopRecording: 'Stop recording',
    camera: 'Camera',
    microphone: 'Microphone',
    cameraNotSelected: 'Not selected',
    microphoneNotSelected: 'Not selected',
    microphoneSwitchNotAvailable: 'Swithing the microphone is not available while recording',
    deviceNotSelected: 'Device is not selected',
    deviceConnectError: 'Failed to connect device',
    deviceConnectErrorReasons:
      'Possible reasons: \n- Device is not working \n- Device is used by another program \n- Access is denied by system settings',
    startCollectingBiometrics: 'Start collecting biometrics',
    autoShootMode: 'Auto shoot mode',
    questionnaire: 'Questionnaire',
    nextQuestion: 'Next question',
    previousQuestion: 'Previous question',
    questionsCounter: 'Question №{{index}} of {{count}}',
    play: 'Start recording',
    pause: 'Pause',
    isRecording: 'recording',
    recordingIsPaused: 'recording is paused',
    pureSpeech: 'Pure speech',
    devicesSetup: 'Devices setup',
    video: 'Video',
    sound: 'Sound',
    sensitivity: 'Sensitivity',
    photoNotTaken: 'The picture is not taken yet',
    takePhoto: 'Take picture',
    maxPhotoCount: 'Maximum number of pictures - {{count}}',
    biometricsCollectionModeIsActive: 'Biometrics collection mode is active',
    stopRecordingOnCloseConfirmation:
      'Do you want to stop biometrics collection and close the card?',
    deviceDisconnected: 'Device is disconnected',
  },
  changePassword: 'Change password',
  changePasswordTitle: 'Change password',
  chooseRole: 'Select role',
  close: 'Close',
  collapse: 'Collapse',
  commentary: 'Comment',
  copyCardId: 'Copy entry ID',
  copyId: 'Copy ID',
  create: 'Create',
  createRole: 'Create role',
  createUser: 'Create user',
  delete: 'Delete',
  download: 'Download',
  edit: 'Edit',
  enrollment: 'Enrollment',
  enterComment: 'Enter comment',
  enterFirstname: 'Enter first name',
  enterFullName: 'Enter full name',
  enterLastname: 'Enter last name',
  enterMiddlename: 'Enter middle name',
  enterNewPassword: 'Enter new password',
  enterPassword: 'Enter password',
  enterUsername: 'Enter username',
  error: 'Error',
  errorOccurred: 'Error occurred',
  unknownError: 'Unknown error',
  exclude: 'Exclude',
  exit: 'Exit',
  fieldError: {
    InvalidCount: 'Error in the number of field values',
    InvalidDataType: 'Invalid field data type',
    InvalidDateFormat: 'Error in date format',
    InvalidDateTimeFormat: 'Error in date and time format',
    InvalidValue: 'Entered value does not match the required format',
    MissingRequired: 'Obligatory field is empty',
    NotUniqueValue: 'Specified value is not unique',
    UnknownCustomType: 'Unknown field type',
    UnknownField: 'Unknown field',
    error: 'Incorrect value in field "{{field}}": ',
  },
  fileInProgress: 'File not processed yet',
  fileIsNotFound: 'File not found',
  fileWasPreviouslyUploaded: 'File previously uploaded to the system as «{{name}}»',
  firstname: 'First name',
  fullname: 'Full name',
  gallery: 'Gallery mode',
  gridTable: {
    hideCol: 'Hide column',
    showCols: 'Settings',
    sortAsc: 'Ascending order',
    sortDesc: 'Descending order',
    unsort: 'Reset',
  },
  group: 'Department',
  groups: {
    addUser: 'Add user',
    confirmDelete: 'Do you really want to delete department «{{name}}» ?',
    confirmDeleteNote:
      'This action cannot be undone. Once deleted, department cannot be assigned to user',
    confirmExclude: 'Do you really want to exclude the selected user from department?',
    confirmExcludeNote: 'User can be included in department using the “Add user” button',
    createGroup: 'Create department',
    deleteGroup: 'Delete department',
    editGroup: 'Edit department',
    enterGroupName: 'Enter department name',
    exclude: 'Exclude',
    excludeFromGroup: 'Exclude from department',
    excludeUserFromGroup: 'Exclude user from department',
    groupName: 'Department name',
    groups: 'Departments',
    selectUser: 'Select user',
  },
  id: 'ID',
  imagesComparison: 'Photo comparison',
  inProgress: 'In progress',
  include: 'Include',
  invalidDate: 'Invalid date',
  isBanned: 'Blocked',
  lastname: 'Last name',
  list: 'Display as list',
  login: 'Login',
  managingRoles: {
    areYouSureDeleteRole: 'Do you really want to delete role',
    choosePrivileges: 'Select privileges',
    collapseAll: 'Collapse all',
    deleteRole: 'Delete role',
    enterRoleName: 'Enter role name',
    expandAll: 'Expand all',
    managingPrivs: 'Manage privileges',
    privileges: 'Privileges',
    roleCreation: 'Create role',
    roleEdit: 'Edit role',
    roleName: 'Role name',
    searchPlaceholder: 'Enter privilege',
  },
  messages: {
    audioModelDeleted: 'Audio model successfully deleted',
    audioModelEdit: 'Audio model successfully updated',
    audioUploaded: 'Audio successfully uploaded',
    authError: 'Authentication error:',
    cardFoundInHotlist: 'Entry «{{name}}» found in the hotlist!',
    cardsExported: 'Entries sent to export: {{ids}}',
    errorDeleteRole: 'Error while deleting role',
    faceModelDeleted: 'Face model successfully deleted',
    faceModelEdit: 'Face model successfully updated',
    failedCopyID: 'Failed to copy ID',
    failedToDeleteFile: 'Failed to delete file',
    fileDeletedSuccessful: 'File {{fileName}} successfully deleted',
    fileUploaded: 'File successfully uploaded',
    idCopied: 'ID successfully copied',
    invalidFileFormat: 'Invalid file format',
    openCard: 'Open entry',
    photoUploaded: 'Photo successfully uploaded',
    playerIsNotResponding: 'Player not responding',
    roleCreatedSuccessful: 'Role successfully created',
    rolesDeleted: 'Roles successfully deleted',
    roleUpdatedSuccessful: 'Role successfully updated',
    serverIsNotResponding: 'Server not responding, please try again later',
    userChangePasswordSuccessful: 'Password successfully changed',
    userCreatedSuccessful: 'User successfully created',
    userUpdatedSuccessful: 'User successfully updated',
    userWasAdded: 'User {{username}} successfully added to department «{{groupName}}»',
    userWasExcluded: 'User {{username}} successfully excluded from department «{{groupName}}»',
    usersBlocked: 'Users successfully blocked',
    usersUnblocked: 'Users successfully unblocked',
    usersWereAdded: 'Users {{usernames}} successfully added to department «{{groupName}}»',
    usersWereExcluded: 'Users {{usernames}} successfully excluded from department «{{groupName}}»',
    groupCreated: 'Department successfully created',
    groupEdited: 'Department successfully updated',
    groupDeleted: 'Department successfully deleted',
    filesDeleted: 'Files successfully deleted',
    commentUpdated: 'Comment successfully updated',
    cardsDeleted: 'Cards successfully deleted',
    deviceSettingsWereUpdated: 'Device settings were successfully updated',
    deviceWasDeleted: 'Device was successfully deleted',
    devicesWereDeleted: 'Devices were successfully deleted',
    privilegesWereUpdated: 'Privileges were successfully updated',
    failedToStartRecording: 'Failed to start recording',
    errorCalculatingPureSpeech: 'Error while calculating pure speech amount',
    versionAlreadyExists: 'Distribution with version {{version}} already exists',
    distributionDeleted: 'Distribution was successfully deleted',
  },
  middlename: 'Middle name',
  multipass: {
    cardId: 'Entry ID',
    addFacialModel: 'Add face model',
    addInCard: 'Save to entry',
    addedBy: 'Added by',
    additionDate: 'Date added',
    allPeriod: 'All period',
    audioDuration: 'Audio duration',
    autoMarkup: 'Auto markup',
    biometrics: 'Biometrics',
    birthDate: 'Date of birth',
    calendar: 'Calendar',
    cardErrorCreated: 'Error while creating entry',
    cardExported: 'Entry successfully exported',
    cardExportedWithErrors: 'Entry exported with errors',
    cardSuccessfullyCreated: 'Entry successfully created',
    cardSuccessfullyEdited: 'Entry successfully modified',
    cardSuccessfullyExported: 'Entry successfully exported',
    cardWasChangedAfterExport: 'Entry modified after export',
    closePlayer: 'Hide audio player',
    comment: 'Description',
    completed: 'Completed',
    createEntry: 'Create entry',
    createNewEntry: 'Create new entry',
    createdDateTime: 'Date and time added',
    createdTime: 'Loaded',
    deleteFileModal: {
      textBody: 'Do you really want to delete file?',
      textButtonConfirm: 'Delete',
      title: 'Delete file',
    },
    desktop: 'Desktop',
    dictors: 'Speakers',
    editedDateTime: 'Date and time successfully modified',
    entries: 'Entries',
    entryCreated: 'Entry successfully created',
    errorCardNotExported: 'Export error. Failed to export entry',
    errorWhenLoadingCard: 'Error while loading entry',
    errorWhenLoadingTemplates: 'Error while loading templates',
    excluded: 'Excluded',
    extension: 'Extension',
    fileDetails: 'File details',
    fileList: 'File list',
    fileName: 'File name',
    fileProcessNotFound: 'File process not found',
    fileProcessingError:
      'Error while processing file. Please upload file again or contact system administrator',
    foundedDictors: 'Speakers detected',
    gender: 'Gender',
    generalInfo: 'Entry',
    isInHotList: 'Pertains to hotlist',
    infoAudio: 'Audio details',
    infoPhoto: 'Photo details',
    interactiveCard: 'Interactive entry',
    manualMarkup: 'Manual markup',
    missingDataText: 'Upload audio to get started',
    missingDataTitle: 'Audio',
    modelNotFound: 'Model not found',
    modelQuality: 'Model quality',
    excellentQuality: 'Excellent quality',
    goodQuality: 'Good quality',
    badQuality: 'Bad quality',
    nameEdit: 'Edited by',
    nameLoad: 'Added by',
    newEntry: 'New entry',
    newEntryFrom: 'New entry from',
    noTemplatesFound: 'No entry templates found',
    noUploadedAudio: 'No audio files uploaded',
    noUploadedFiles: 'No files uploaded',
    openFullSize: 'Open in full size',
    closeFullscreen: 'Close fullscreen view',
    openInNewTab: 'Open in new tab',
    openPlayer: 'Show audio player',
    passport: 'Person ID',
    personalData: 'Personal data',
    pureSpeechDuration: 'Pure speech duration',
    recommendedData: 'Recommended specifications',
    resource: 'Resource',
    samples: 'Samples',
    selectFileTitle: 'Select file to view speaker list',
    selectedMarkup: 'Selected markup',
    setAsMain: 'Set as face model',
    setAsMainSpeaker: 'Set as voice model',
    setAsMainPhoto: 'Set as main',
    speechDuration: 'Speech duration',
    status: 'Status',
    toDisplayEntrySelectOneObject: 'Select object to display entry',
    today: 'Today',
    tooltipDuplicate: 'Duplicate',
    tooltipVerified: 'Verified',
    totalDuration: 'Total duration',
    typeFile: 'Type',
    uploadAudio: 'Upload audio file to create voice model',
    verifyFile: 'Mark as verified',
    yesterday: 'Yesterday',
    fileProcessStatus: {
      [EFileProcessStatus.NEW]: 'In the queue',
      [EFileProcessStatus.INPROGRESS]: 'In processing',
      [EFileProcessStatus.COMPLETED]: 'Completed',
      [EFileProcessStatus.FAILED]: 'Failed',
      [EFileProcessStatus.DUPLICATE]: 'Duplicate',
    },
  },
  name: 'Name',
  new: 'New',
  newPassword: 'New password',
  newPasswordConfirmation: 'Confirm new password',
  password: 'Password',
  passwordConfirmation: 'Confirm password',
  player: {
    algorithm: 'Algorithm',
    audioDuration: 'Audio duration',
    byteUnit: 'byte',
    clipFragmentsLength: 'Overall duration of clipped fragments',
    coe: 'Reverberation RMS',
    dBUnit: 'dB',
    encodingType: 'Type of encoding',
    fileSize: 'File size',
    hzUnit: 'Hz',
    lowerBound: 'Lower limit of speech signal frequency',
    maxLengthNoClip: 'Max. duration of unclipped fragments',
    next: 'Next',
    noiseFragmentsLength: 'Overall duration of noise fragments',
    prev: 'Previous',
    quality: 'Quality',
    reverberation: 'Reverberation',
    reverberationMoments: 'Reverberation moments',
    samplingRate: 'Sampling rate',
    segmentsLength: 'Length of selected fragments',
    signalAndNoise: 'SNR',
    slowDown: 'Slow down',
    speechDuration: 'Speech duration',
    speedUp: 'Speed up',
    upperBound: 'Upper limit of speech signal frequency',
    channelNumber: 'Channel {{channel}}',
  },
  privileges: {
    ADMINISTRATION: 'Administration',
    [PrivilegeCode.CREATE_CARDS]: {
      actions: 'View entries by user department; | Create entry',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Create entry',
    },
    [PrivilegeCode.CREATE_GROUPS]: {
      actions: 'View list of departments and their members; | Create new department',
      description: 'Possible actions in the Administration / Departments section:',
      title: 'Create department',
    },
    [PrivilegeCode.CREATE_ROLES]: {
      actions: 'View role list and their privileges; | Create role',
      description: 'Possible actions in the Administration / Roles section:',
      title: 'Create role',
    },
    [PrivilegeCode.CREATE_USER]: {
      actions: 'View user list; | Create user',
      description: 'Possible actions in the Administration/Users section:',
      title: 'Create user',
    },
    [PrivilegeCode.DELETE_CARDS]: {
      actions: 'View entries by user department; | Delete entry',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Delete entry',
    },
    [PrivilegeCode.DELETE_GROUPS]: {
      actions: 'View department list and their members; | Delete department',
      description: 'Possible actions in the Administration / Departments section:',
      title: 'Delete department',
    },
    [PrivilegeCode.DELETE_ROLES]: {
      actions: 'View role list and their privileges; | Delete role',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Delete role',
    },
    [PrivilegeCode.EDIT_CARDS]: {
      actions: 'View entries by user department; | Edit entry',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Edit entry',
    },
    [PrivilegeCode.EDIT_GROUPS]: {
      actions: 'View list of departments and their members; | Edit a department.',
      description: 'Possible actions in the Administration / Departments section:',
      title: 'Edit department',
    },
    [PrivilegeCode.EDIT_ROLES]: {
      actions:
        'View role list and their privileges; | Edit roles; | Manage privileges - Add privileges to roles and remove them',
      description: 'Possible actions in the Administration / Roles section:',
      title: 'Edit role',
    },
    [PrivilegeCode.EDIT_USER]: {
      actions: 'View user list; | Edit user; | Reset user password',
      description: 'Possible actions in the Administration / Users section:',
      title: 'Edit user',
    },
    ENROLLMENT: 'Enrollment',
    [PrivilegeCode.EXPORT_CARDS]: {
      actions: 'View entries by user department; | Export entries',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Export entries',
    },
    [PrivilegeCode.EXPORT_CARD_FILES]: {
      actions: 'View entries by department; | Export entry files',
      description: 'Possible actions in Enrollment / Entries section:',
      title: 'Export entry files',
    },
    FILE_MANAGEMENT: 'Manage files',
    [PrivilegeCode.LOCK_UNLOCK_USER]: {
      actions: 'View user list; | Block user; | Unblock user',
      description: 'Possible actions in the Administration / Users section:',
      title: 'Block/unblock user',
    },
    USER_ACCOUNT_MANAGEMENT: 'Manage user accounts',
    [PrivilegeCode.USER_GROUP_AFFILIATION]: {
      actions:
        'View department list and their members; | Add user to department; | Remove user from department',
      description: 'Possible actions in the Administration / Departments section:',
      title: 'Add users to departments and exclude users from departments',
    },
    USER_GROUP_MANAGEMENT: 'Manage user departments',
    USER_ROLE_MANAGEMENT: 'Manage user roles',
    [PrivilegeCode.VIEW_ALL_CARDS]: {
      actions: 'View all entries',
      description: 'Possible actions in the Enrollment / Entries section:',
      title: 'View all entries',
    },
    [PrivilegeCode.VIEW_CARDS]: {
      actions: 'View entries by user department',
      description: 'Possible actions in the Enrollment / Entries section:',
      title: 'View entries',
    },
    [PrivilegeCode.VIEW_GROUPS]: {
      actions: 'View department list with their members',
      description: 'Possible actions in the Administration / Users section:',
      title: 'View departments',
    },
    [PrivilegeCode.VIEW_ROLES]: {
      actions: 'View role list and their privileges',
      description: 'Possible actions in the Administration / Users section:',
      title: 'View roles',
    },
    [PrivilegeCode.VIEW_USERS]: {
      actions: 'View user list',
      description: 'Possible actions in the Administration / Users section:',
      title: 'View users',
    },
    DEVICES: 'Devices',
    DEVICE_MANAGEMENT: 'Manage devices',
    [PrivilegeCode.ACCESS_TO_DEVICES]: {
      actions:
        'View devices; | Edit device settings; | Delete device; | Update device software; | Edit device privileges; | Download device logs;',
      description: 'Possible actions in the Devices section:',
      title: 'Access to devices',
    },
    reference: 'Reference',
  },
  portraitCharacteristics: {
    title: 'Portrait characteristics',
    NoDarkGlasses: 'No dark glasses',
    NoGlasses: 'No glasses',
    NoBeard: 'No beard',
    NoMustache: 'No mustache',
    NoSmile: 'No smile',
    MouthClosed: 'Mouth closed',
    LeftEyeOpen: 'Left eye open',
    RightEyeOpen: 'Right eye open',
    FrontalPoseDeviation: 'Frontal pose deviation',
    Exposure: 'Exposure',
    Sharpness: 'Sharpness',
    GrayscaleDensity: 'Grayscale density',
    DeviationFromUniformLighting: 'Uniform lighting',
    Hotspots: 'No glare',
  },
  refresh: 'Refresh',
  repeatNewPassword: 'Repeat new password',
  repeatPassword: 'Repeat password',
  resume: 'Resume',
  role: 'Role',
  roleManagement: 'Manage roles',
  roles: 'roles',
  save: 'Save',
  saveAndClose: 'Save and close',
  saveToStartWithBioscanner: 'Save your job to get started with BioScanner',
  searchQuery: 'Search queries',
  selectGroup: 'Select department',
  selected: 'Selected',
  sets: 'Sets',
  sideFilterSelectPlaceholder: 'Select value',
  sideFilterStringPlaceholder: 'Enter value',
  speaker: 'Speaker',
  thisActionCannotBeUndone: 'This action cannot be undone',
  tile: 'Display as tile',
  unblock: 'Unblock',
  unblockUser: 'Unblock user',
  upload: 'Upload',
  userCreation: 'Create user',
  userEdit: 'Edit user',
  username: 'Username',
  users: 'Users',
  validation: {
    invalidFormat: 'Invalid format',
    maxCount: 'Max. number of field values is {{count}}',
    maxLength: 'Max. length: {{count}} characters',
    minLength: 'Min. length: {{count}} characters',
    passwordsNotMatch: 'Passwords do not match',
    required: 'This field cannot be left blank',
    requiredOptions: 'Please select one of the options',
    shouldBeEmail: 'Invalid email format',
  },
  youSureWantBlockUsers: 'Do you really want to block user {{name}}?',
  youSureWantUnblockUsers: 'Do you really want to unblock user {{name}}?',
  devices: {
    devices: 'Devices',
    status: {
      [DeviceStatus.ONLINE]: 'Online',
      [DeviceStatus.OFFLINE]: 'Offline',
      [DeviceStatus.UPDATING]: 'Updating',
      [DeviceStatus.ERROR]: 'Error',
    },
    deviceName: 'Device name',
    dateModified: 'Modification date',
    networkAddress: 'Network address',
    softwareVersion: 'Software version',
    department: 'Department',
    dateRegistered: 'Registration date',
    authorizationType: 'Authorization type',
    authorizationTypes: {
      [AuthorizationType.QR_CODE]: 'QR-code',
      [AuthorizationType.WITHOUT_AUTHORIZATION]: 'Without authorization',
    },
    personCardAccess: 'Access to person card',
    personCardAccesses: {
      [AccessToPersonCard.WITHOUT_CREDENTIALS]: 'Without credentials',
      [AccessToPersonCard.WITH_CREDENTIALS]: 'With credentials',
    },
    accessTypesDescription:
      'Setting up a method for opening a person’s card in Sampler using a button on the device',
    deviceId: 'Device ID',
    blocked: 'Blocked',
    searchPlaceholder: 'Search by device name or ID',
    deviceSettings: 'Device settings',
    exportLogs: 'Export device logs',
    export: 'Export',
    updateSoftware: 'Update software',
    softwareVersions: 'Software versions',
    selectSoftwareVersion: 'Select software version',
    devicesSelectedForUpdate: 'Devices selected for update:',
    availableDistributions: 'Available distributions:',
    deviceWillBeUpdated: 'Device will be updated to version {{version}}',
    deleteSoftwareVersion: 'Delete software version',
    confirmDeleteSoftwareVersion: 'Are you sure you want to delete distribution {{version}}?',
    cantDeleteSelectedVersion: 'Can not delete selected software version',
    noDistributionsForUpdate: 'No distributions to update',
    privilegesSettings: 'Privileges for unauthorized access',
    deleteDevices: 'Delete devices',
    confirmDeleteOne: 'Are you sure you want to delete device?',
    confirmDeleteMultiple: 'Are you sure you want to delete selected devices ({{count}})?',
    blockDevice: 'Block the device',
    period: 'Period',
  },
  units: {
    byte: 'B',
    kilobyte: 'KB',
    megabyte: 'MB',
  },
};
