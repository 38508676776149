import { CallAPIType, METHODS } from '@/types';
import rootApiService from './root.api.service';

type LanguageResponse = {
  language: string;
};

class LanguageService {
  getAll = async (): Promise<string[] | null> => {
    const APIOptions: CallAPIType = {
      path: 'language/all',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<string[]>(APIOptions);

    return apiResponse.res;
  };

  getCurrent = async (): Promise<string | null> => {
    const APIOptions: CallAPIType = {
      path: 'language',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<LanguageResponse>(APIOptions);

    return apiResponse.res?.language || null;
  };

  setLanguage = ({ language }: LanguageResponse) => {
    const APIOptions: CallAPIType = {
      path: `language/${language}`,
      method: METHODS.PUT,
    };

    return rootApiService.callAPI<LanguageResponse>(APIOptions);
  };
}

export default new LanguageService();
