import { calculateFileSha1 } from '@/utils/calculateFileSha1';
import { FileWithHash } from '@/components/searchRequestPage/dialogs/newRequestDialog/fileWithHash';
import filesApiService from '@/services/files.service';
import { SnackbarActionType } from '@/types';

export const getKeyFromFileInfo = (file: File) => `${file.lastModified}${file.size}${file.name}`;

export const uploadFileIfNotExistsAndGetHash = async (
  file: File,
  snackbarAction: SnackbarActionType
) => {
  const id = getKeyFromFileInfo(file);
  const [hash, bytes] = await calculateFileSha1(file);
  const hashFile = new FileWithHash(id, file, hash, bytes);
  const exists = await filesApiService.checkIfExists(hashFile.hash, snackbarAction);

  if (!exists) {
    await filesApiService.uploadFile(
      hashFile.fileObj.name,
      hashFile.hash,
      hashFile.data,
      snackbarAction
    );
  }

  return hashFile.hash;
};

export const getApiRequestFileUrl = (url: string | undefined) => {
  return `${process.env.PUBLIC_URL}/api/v1/file?url=${url ?? ''}`;
};
