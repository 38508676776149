import { makeAutoObservable } from 'mobx';
import { MediaDeviceDirection, MediaDevicesStoreInitProps, MediaDevice } from './types';
import { v4 as uuidv4 } from 'uuid';
import { showErrorNotification } from '@/utils/notifications';
import { t } from 'i18next';
export class MediaDevicesStore {
  isInited = false;
  componentStorageId?: string;
  allDevices?: MediaDeviceInfo[];
  allDevicesByType: Record<MediaDevice, MediaDeviceInfo[]> = { audio: [], video: [] };
  mediaRequestOptions?: MediaStreamConstraints = { video: true, audio: true };
  selectedAudioInputDeviceId?: string;
  selectedVideoInputDeviceId?: string;

  isAudioDevicePermissionGranted = false;
  isVideoDevicePermissionGranted = false;
  currentRecordSessionId = '';
  isRecordingActive = false;

  constructor(props?: MediaDevicesStoreInitProps) {
    makeAutoObservable(this, {}, { autoBind: true });
    props?.constraints && this.setMediaRequestOptions(props.constraints);
  }

  setIsRecordingActive(value: boolean) {
    this.isRecordingActive = value;
  }

  setCurrentRecordSessionId(value: string) {
    this.currentRecordSessionId = value;
  }

  toggleIsRecording() {
    const shouldStart = !this.isRecordingActive;
    this.setIsRecordingActive(shouldStart);
    if (this.componentStorageId) {
      localStorage.setItem(this.componentStorageId, shouldStart.toString());
    }

    this.setCurrentRecordSessionId(shouldStart ? uuidv4() : '');
  }

  clearStorageRecordingState() {
    if (this.isRecordingActive) {
      this.toggleIsRecording();
    }
  }

  setSelectedAudioInputDeviceId(value: string) {
    this.selectedAudioInputDeviceId = value;
    this.storeDefaultDeviceIds('audio', 'input', value);
  }

  setSelectedVideoInputDeviceId(value: string) {
    this.selectedVideoInputDeviceId = value;
    this.storeDefaultDeviceIds('video', 'input', value);
  }

  setIsAudioDevicePermissionGranted(value: boolean) {
    this.isAudioDevicePermissionGranted = value;
  }

  setIsVideoDevicePermissionGranted(value: boolean) {
    this.isVideoDevicePermissionGranted = value;
  }

  storeDefaultDeviceIds(deviceType: MediaDevice, dir: MediaDeviceDirection, id?: string) {
    if (this.componentStorageId) {
      if (!id) {
        localStorage.removeItem(
          `selectedMediaDeviceId-${deviceType}-${dir}-${this.componentStorageId}`
        );

        return;
      }

      localStorage.setItem(
        `selectedMediaDeviceId-${deviceType}-${dir}-${this.componentStorageId}`,
        id
      );
    }
  }

  get selectedAudioInputDevice() {
    return (
      this.selectedAudioInputDeviceId &&
      this.allDevices?.find((device) => device.deviceId === this.selectedAudioInputDeviceId)
    );
  }

  get selectedVideoInputDevice() {
    return (
      this.selectedVideoInputDeviceId &&
      this.allDevices?.find((device) => device.deviceId === this.selectedVideoInputDeviceId)
    );
  }

  get hasDevices() {
    return !!this.allDevices?.length;
  }

  getDeviceIdFromStorage(deviceType: MediaDevice, deviceInputType: MediaDeviceDirection) {
    if (this.componentStorageId) {
      return localStorage.getItem(
        `selectedMediaDeviceId-${deviceType}-${deviceInputType}-${this.componentStorageId}`
      );
    }
  }

  syncDefaultDeviceIdsWithLocalStorage() {
    const videoInputDevice = this.getDeviceIdFromStorage('video', 'input');
    const audioInputDevice = this.getDeviceIdFromStorage('audio', 'input');
    audioInputDevice && this.setSelectedAudioInputDeviceId(audioInputDevice);
    videoInputDevice && this.setSelectedVideoInputDeviceId(videoInputDevice);
  }

  setMediaRequestOptions(cons?: MediaStreamConstraints) {
    this.mediaRequestOptions = cons;
  }

  setIsInited(v: boolean) {
    this.isInited = v;
  }

  async init(props?: MediaDevicesStoreInitProps) {
    props?.localStorageId && (this.componentStorageId = props.localStorageId);
    this.syncDefaultDeviceIdsWithLocalStorage();
    await this.setUserMedia();
    this.setIsInited(true);
  }

  async getMediaDeviceStream(deviceType: MediaDevice, deviceId: string) {
    const data = await navigator.mediaDevices.getUserMedia({
      [deviceType]: { advanced: [{ deviceId }] },
    });

    return data;
  }

  setAllDevices(value: MediaDeviceInfo[]) {
    this.allDevices = value;
  }

  setAllDevicesByType(type: MediaDevice, value: MediaDeviceInfo[]) {
    this.allDevicesByType[type] = value;
  }

  async setUserMedia() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(this.mediaRequestOptions);
      if (this.mediaRequestOptions?.audio) {
        this.setIsAudioDevicePermissionGranted(true);
      }
      if (this.mediaRequestOptions?.video) {
        this.setIsVideoDevicePermissionGranted(true);
      }

      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      if (this.mediaRequestOptions?.audio) {
        this.setIsAudioDevicePermissionGranted(false);
        this.setSelectedAudioInputDeviceId('');
      }
      if (this.mediaRequestOptions?.video) {
        this.setIsVideoDevicePermissionGranted(false);
        this.setSelectedVideoInputDeviceId('');
      }

      showErrorNotification({
        header: t('interactiveMode.deviceConnectError'),
        message: t('interactiveMode.deviceConnectErrorReasons'),
      });
    }

    try {
      await this.requestAndSetMediaDevices();
    } catch (error) {
      console.error(error);
    }
  }

  async requestAndSetMediaDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const devicesByType = devices.reduce(
      (acc, current, idx) => {
        if (current.deviceId) {
          acc[current.kind].push(current);
        }

        return acc;
      },
      {
        audioinput: [],
        videoinput: [],
        audiooutput: [],
      } as Record<MediaDeviceKind, MediaDeviceInfo[]>
    );
    devices.length && this.setAllDevices(devices);
    devicesByType.audioinput.length && this.setAllDevicesByType('audio', devicesByType.audioinput);
    devicesByType.videoinput.length && this.setAllDevicesByType('video', devicesByType.videoinput);
  }
}
