/* eslint-disable max-len */

type MainLogoProps = {
  width?: string;
  height?: string;
  color?: string;
};

const MainLogo = ({ width = '32', height = '32', color = 'white' }: MainLogoProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M8.71824 2.01256L1.85619 5.94965C0.70086 6.60572 0 7.81528 0 9.12739V23.0479C0 24.36 0.70086 25.569 1.85619 26.2256L8.71824 30.1633C8.82352 30.1982 8.92748 30.1633 8.92748 30.0241V27.4694C8.92748 27.401 8.89286 27.365 8.85691 27.3301L4.09566 24.6026C3.11614 24.0492 2.52118 23.0479 2.52118 21.9434V10.2325C2.52118 9.12739 3.11614 8.12616 4.09566 7.57336L8.85691 4.84458C8.89286 4.80974 8.92748 4.77497 8.92748 4.70653V2.15066C8.92748 2.01256 8.82352 1.97778 8.71824 2.01256ZM23.2818 2.01256L30.1439 5.94965C31.2991 6.60572 32 7.81528 32 9.12739V23.0479C32 24.3601 31.2991 25.569 30.1439 26.2257L23.2818 30.1633C23.1765 30.1982 23.0724 30.1633 23.0724 30.0241V27.4694C23.0724 27.401 23.1071 27.365 23.143 27.3301L27.9043 24.6026C28.8838 24.0492 29.4788 23.0479 29.4788 21.9434V10.2325C29.4788 9.12739 28.8838 8.12616 27.9043 7.57336L23.143 4.84458C23.1071 4.80974 23.0724 4.77497 23.0724 4.70653V2.15066C23.0724 2.01256 23.1765 1.97778 23.2818 2.01256Z'
          fill={color}
        />
        <path
          d='M22.2426 20.6628L16.8863 23.7025C16.7756 23.772 16.7804 23.8753 16.8863 23.945L19.5122 25.4299L20.4928 25.9823C20.5376 26.0047 20.5682 25.9982 20.6032 25.9908C20.6224 25.9867 20.643 25.9823 20.6681 25.9823L25.6386 23.1155C26.4447 22.6664 26.9333 21.8369 26.9333 20.9389V16.2125V11.486C26.9333 10.5881 26.4447 9.7585 25.6386 9.30953L20.6681 6.44274C20.643 6.44274 20.6224 6.43835 20.6032 6.43424C20.5682 6.42679 20.5376 6.42027 20.4928 6.44274L19.5122 6.99494L16.8863 8.47997C16.7804 8.54962 16.7756 8.65282 16.8863 8.72246L22.2426 11.7622C22.7683 12.0383 23.0487 12.5905 23.0487 13.1428V16.2125V19.2821C23.0487 19.8345 22.7683 20.3866 22.2426 20.6628ZM9.72515 20.6628L15.0814 23.7025C15.192 23.7721 15.1873 23.8753 15.0814 23.945L12.4556 25.4299L11.4749 25.9823C11.4301 26.0047 11.3995 25.9982 11.3646 25.9908C11.3453 25.9867 11.3247 25.9823 11.2996 25.9823L6.32908 23.1155C5.52296 22.6664 5.03442 21.8369 5.03442 20.9389V16.2125V11.486C5.03442 10.5881 5.52296 9.7585 6.32908 9.30954L11.2996 6.44274C11.3247 6.44274 11.3453 6.43835 11.3646 6.43425C11.3995 6.42679 11.4301 6.42028 11.4749 6.44274L12.4556 6.99495L15.0814 8.47997C15.1873 8.54962 15.192 8.65282 15.0814 8.72246L9.72515 11.7622C9.19944 12.0383 8.91901 12.5905 8.91901 13.1428V16.2125V19.2821C8.91901 19.8345 9.19944 20.3866 9.72515 20.6628Z'
          fill='#008697'
        />
      </g>
    </svg>
  );
};

export default MainLogo;
