export enum Predicate {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EQ = 'EQ',
  NOT_EQ = 'NOT_EQ',
  GR = 'GR',
  LESS = 'LESS',
  EQ_GR = 'EQ_GR',
  EQ_LESS = 'EQ_LESS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
  NOT_NULL = 'NOT_NULL',
}
