import React, { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

import { TopMenu } from './TopMenu';
import { makeStyles } from '@/hooks/makeStyles';

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    '>[class*="Layout-module__layout"]': {
      gap: 0,
    },
  },
}));

export const MenuDrawer: FC<Props> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Stack direction='column' height='100vh' overflow='hidden'>
      <TopMenu />
      <Box
        className={classes.wrapper}
        component='main'
        flexGrow={1}
        display='flex'
        flexDirection='column'
        overflow='hidden'
      >
        {children}
      </Box>
    </Stack>
  );
};
