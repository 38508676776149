import { action, makeObservable, observable } from 'mobx';

export type ProcessAlreadyExists = {
  processId: number;
};

class ModalStore {
  constructor() {
    makeObservable(this);
  }

  @observable isExportModalOpen = false;
  @observable isErrorModalOpen = false;
  @observable isConfirmModalOpen = false;
  @observable isProcessesTableOpen = false;
  @observable processAlreadyExistsError: ProcessAlreadyExists | null = null;

  @action setIsExportModalOpen = (isOpen: boolean) => {
    this.isExportModalOpen = isOpen;
  };

  @action setIsErrorModalOpen = (isOpen: boolean) => {
    this.isErrorModalOpen = isOpen;
  };

  @action setIsConfirmModalOpen = (isOpen: boolean) => {
    this.isConfirmModalOpen = isOpen;
  };

  @action setIsProcessesTableOpen = (isOpen: boolean) => {
    this.isProcessesTableOpen = isOpen;
    if (!isOpen) {
      this.setProcessAlreadyExistsError(null);
    }
  };

  @action setProcessAlreadyExistsError = (value: ProcessAlreadyExists | null) => {
    this.processAlreadyExistsError = value;
  };
}

export default ModalStore;
