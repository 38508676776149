import { action, computed, makeAutoObservable } from 'mobx';

import { initSpeakerInfo } from '../../recordPlayer/utils';

import { ITaskResponse } from '@/apiTypes';

export enum AUDIO_FILE_STATUS {
  ERROR,
  LISTED,
  EXISTS,
  MARKUPED,
  READY,
}

export class FileWithHash {
  public deleted = false;
  private _audioSrc?: string;
  private _audioMarkup?: ITaskResponse;
  private _audioMarkupStatus?: AUDIO_FILE_STATUS;
  selected = false;

  @action setSelected(val: boolean) {
    this.selected = val;
  }

  @action setAudioSrc(val: string) {
    this._audioSrc = val;
  }

  @action setAudioMarkup(val: ITaskResponse) {
    this._audioMarkup = val;
    this._audioMarkup.speakers?.forEach((v) => {
      initSpeakerInfo(v);
    });
  }
  @action setAudioMarkupStatus(val: AUDIO_FILE_STATUS | undefined) {
    this._audioMarkupStatus = val;
  }

  @computed get audioMarkup(): ITaskResponse | undefined {
    return this._audioMarkup;
  }

  @computed get audioMarkupStatus(): AUDIO_FILE_STATUS | undefined {
    return this._audioMarkupStatus;
  }

  @computed get audioSrc(): string | undefined {
    return this._audioSrc;
  }

  constructor(
    public id: string,
    public fileObj: File,
    public hash: string,
    public data: Uint8Array,

    audioMarkupStatus?: AUDIO_FILE_STATUS
  ) {
    if (audioMarkupStatus) {
      this._audioMarkupStatus = audioMarkupStatus;
    }
    makeAutoObservable(this);
  }
}
