import { appColors } from '@/Providers/ThemeProvider/colors';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const AppLoader = () => {
  return (
    <Box height='100vh' width='100vw' display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress size={50} disableShrink thickness={4} sx={{ color: appColors.bgPrimary }} />
    </Box>
  );
};
