import { EntityWithIdAndErrors, EntityWithId } from '@/types/entity';
import rootApiService from './root.api.service';
import { EDataGridStoreSortMode, IDataGridStoreData, IDataGridStoreState } from 'types/grid';
import {
  prepareFiltersRequestBody,
  prepareFiltersResponseData,
  formatColumnFilters,
  formatFilters,
} from 'utils/api/filters';
import { trimObjectValues } from 'utils/object';
import { APIResponseType, METHODS } from '@/types';
import { ICardsGridItem } from '@/types/card';

interface GetFiltersDataRequest<T> {
  filters: IDataGridStoreState<T>;
  localData?: T[];
  searchString?: string;
}

interface GetEntitiesByIdsRequest {
  ids: number[];
}

interface GetEntityByIdRequest {
  id: number | string;
}

interface UpdateEntityByIdRequest<T> {
  id: number | string;
  entity: T;
}

interface CreateEntityRequest<T> {
  entity: Partial<T>;
}

interface DeleteEntityRequest {
  id: number | string;
}

interface DeleteEntitiesRequest {
  ids: (number | string)[];
}

export class EntityApi {
  url = '';
  api = rootApiService;

  constructor(url: string) {
    this.url = url;
  }

  private sortAndPaginateData<T>({
    data,
    filters,
  }: {
    data: T[];
    filters: IDataGridStoreState<T>;
  }) {
    const { sortField, sort } = filters;

    return [...data]
      .sort((a, b) => {
        const first = String(a[sortField]).toLowerCase();
        const second = String(b[sortField]).toLowerCase();

        return sort === EDataGridStoreSortMode.ASC
          ? first.localeCompare(second)
          : second.localeCompare(first);
      })
      .slice(filters.offset, filters.offset + filters.limit + 1);
  }

  async getFiltersData<T>({
    filters,
    localData,
  }: GetFiltersDataRequest<T>): Promise<IDataGridStoreData<T>> {
    const columnFilters = formatColumnFilters(filters.columnFilters);
    const jsonFilters = [...filters.columnFilters.flatMap((f) => f.jsonFields ?? [])];
    const regularFilters = formatFilters(filters.regularFilters);
    const fastFilters = formatFilters(filters.fastFilters);
    const orFilters = formatFilters(filters.orFilters);

    const filterBody = prepareFiltersRequestBody({
      body: {
        offset: filters.offset,
        limit: filters.limit + 1,
        sortByFields: [
          {
            name: filters.sortField,
            order: filters.sort,
          },
        ],
        fields: [...columnFilters, ...regularFilters],
        fastFields: fastFilters,
        orFields: orFilters,
        jsonFields: jsonFilters.length > 0 ? jsonFilters : undefined,
      },
      filters,
    });

    let data: T[] | null = null;

    if (localData) {
      data = this.sortAndPaginateData({ data: localData, filters });
    } else {
      const { res } = await this.api.callAPI<T[]>({
        path: this.url.concat(
          filters.searchString ? `/filter?searchString=${filters.searchString}` : '/filter'
        ),
        body: filterBody,
        method: METHODS.POST,
      });

      data = res || [];
    }

    return prepareFiltersResponseData({ data, filters });
  }

  async getAllEntities<T extends EntityWithId>(): Promise<APIResponseType<T[]>> {
    return await this.api.callAPI<T[]>({
      path: this.url.concat('/all'),
      method: METHODS.GET,
    });
  }

  async getEntityById<T extends EntityWithId>({
    id,
  }: GetEntityByIdRequest): Promise<APIResponseType<T>> {
    return await this.api.callAPI<T>({
      path: this.url.concat(`/${id}`),
      method: METHODS.GET,
    });
  }

  async getEntitiesByIds<T extends EntityWithId>({
    ids,
  }: GetEntitiesByIdsRequest): Promise<APIResponseType<T[]>> {
    return await this.api.callAPI<T[]>({
      path: this.url.concat('/get'),
      body: ids,
    });
  }

  async updateEntityById<T, R>({
    id,
    entity,
  }: UpdateEntityByIdRequest<T>): Promise<APIResponseType<R>> {
    return await this.api.callAPI<R>({
      path: this.url.concat(`/${id}`),
      body: trimObjectValues<T>(entity),
      method: METHODS.PUT,
    });
  }

  async createEntity<T, R>({ entity }: CreateEntityRequest<T>): Promise<APIResponseType<R>> {
    return await this.api.callAPI<R>({
      path: this.url,
      body: trimObjectValues(entity),
      method: METHODS.POST,
    });
  }

  async deleteEntity<T>({ id }: DeleteEntityRequest): Promise<APIResponseType<T>> {
    return await this.api.callAPI<T>({
      path: this.url.concat(`/${id}`),
      method: METHODS.DELETE,
    });
  }

  async deleteEntities<T extends EntityWithIdAndErrors[]>({
    ids,
  }: DeleteEntitiesRequest): Promise<APIResponseType<T>> {
    return await this.api.callAPI<T>({
      path: this.url,
      body: ids,
      method: METHODS.DELETE,
    });
  }

  async getCount(): Promise<number | null> {
    const data = await this.api.callAPI<number>({
      path: this.url.concat('/count'),
      method: METHODS.GET,
    });

    return data.res;
  }
}
