class AuthService {
  getUserAuthToken = () => {
    return localStorage.getItem('t4f_token');
  };

  getAuthHeader = (): string | null => {
    const token = this.getUserAuthToken() || '';

    if (!token) return null;

    return `Bearer ${token}`;
  };
}

export const authService = new AuthService();
