import { ErrorType } from '@/types';

export class ApiError extends Error {
  httpCode?: number;
  errorType?: ErrorType;

  constructor({ httpCode, errorType }: { httpCode?: number; errorType?: ErrorType }) {
    super();
    this.httpCode = httpCode;
    this.errorType = errorType;
  }
}
