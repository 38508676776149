import { action, makeObservable, observable } from 'mobx';

import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { IJobEntity } from '@/apiTypes';

class QueryStore {
  constructor() {
    makeObservable(this);
  }
  @observable isLoading = false;
  @observable queryList: IJobEntity[] = [];
  @observable selectedQueryId: number | null = null;
  @observable private _gridApiRef: GridApiPro | undefined = undefined;
  private _filerPanelVisible = false;

  @action setQueryTableLoader = (isLoadDone: boolean) => {
    this.isLoading = isLoadDone;
  };

  @action selectQuery = (id: number | null) => {
    this.selectedQueryId = id;
  };

  @action addQueries = (list: IJobEntity[]) => {
    this.queryList = list;
  };

  @action getQueryById = (id: number | null) => {
    if (id) {
      return this.queryList.find((item) => item.job_id === id);
    }
  };

  getGridApiRef(): GridApiPro | undefined {
    return this._gridApiRef;
  }

  @action setGridApiRef(ref: GridApiPro) {
    this._gridApiRef = ref;
  }

  @action setFilterPanelVisibleFlag(value: boolean) {
    this._filerPanelVisible = value;
  }

  public toggleFilterPanel(): void {
    if (this._gridApiRef) {
      if (this._filerPanelVisible) {
        this.setFilterPanelVisibleFlag(!this._filerPanelVisible);
        this._gridApiRef.hideFilterPanel();
      } else {
        this.setFilterPanelVisibleFlag(!this._filerPanelVisible);
        this._gridApiRef.showFilterPanel();
      }
    } else {
      console.error('ref of grid for filter not ready');
    }
  }

  clear = () => {
    this.setQueryTableLoader(false);
    this.addQueries([]);
    this.selectQuery(null);
    this.setFilterPanelVisibleFlag(false);
  };
}

export default QueryStore;
