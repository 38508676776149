import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@/types';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';

type Props = {
  children: JSX.Element;
};

export const RequirePermission: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { checkIsPageAllowed, defaultRoute } = useUserPermissionsContext();
  const pageIsAllowed = checkIsPageAllowed(location.pathname);

  return pageIsAllowed ? (
    children
  ) : (
    <Navigate to={defaultRoute || ROUTES.AUTH} replace state={{ path: location.pathname }} />
  );
};
