import { PlayerRegion } from './player/playerRegion';
import { ISpeakerInfo } from './recordingList.store';

interface IPairRegion {
  start: number;
  end: number;
}

function createPairsFromRegions(regions: number[]): IPairRegion[] {
  const pairs: IPairRegion[] = [];
  if (regions.length % 2 === 0) {
    for (let i = 0; i < regions.length; i += 2) {
      pairs.push({ start: regions[i], end: regions[i + 1] });
    }

    return pairs;
  } else {
    throw new Error(
      `Количество регионов должно быть четным, а их сейчас: ${regions.length.toString(10)}`
    );
  }
}
const dictorColors = [
  'rgba(0, 134, 151, 0.6)',
  'rgba(239, 175, 51, 0.6)',
  'rgba(14, 159, 110, 0.6)',
  'rgba(151, 71, 255, 0.6)',
  'rgba(0, 146, 221, 0.6)',

  'rgba(208, 56, 1, 0.6)',
  'rgba(82, 89, 101, 0.6)',
  'rgba(208, 56, 1, 0.6)',
  'rgba(11, 43, 72, 0.6)',
  'rgba(229, 57, 53, 0.6)',
];

function getSpeakerColor(n: number) {
  return dictorColors[(n - 1) % 10];
}

export function initSpeakerInfo(speaker: ISpeakerInfo) {
  speaker.checked = false;
  const color = getSpeakerColor(speaker.speaker_number);
  speaker.color = color;
  const result: PlayerRegion[] = [];
  const pairs = createPairsFromRegions(speaker.ranges);
  const channelNum = speaker.channel_number === 'Left' ? 0 : 1;
  let i = 1;
  pairs.forEach((pair) => {
    result.push(
      new PlayerRegion(
        pair.start + pair.end + i,
        pair.start,
        pair.end,
        false,
        false,
        false,
        color,
        channelNum
      )
    );
    i += 1;
  });
  speaker.regions = result;
}

export function createRegionsForPlayer(speakers: ISpeakerInfo[]): PlayerRegion[] {
  let result: PlayerRegion[] = [];
  speakers.forEach((speaker) => {
    if (speaker.checked && speaker.regions) {
      result = [...result, ...speaker.regions];
    }
  });

  return result;
}
