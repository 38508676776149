import { IUser } from '@/types';
import { PrivilegeCode } from '@/types/role';
import { useCallback } from 'react';

export const useHavePermissions = (userData: IUser) => {
  const havePermissions = useCallback(
    (condition: PrivilegeCode[][]) => {
      const userPrivs = userData.privileges || [];

      return condition.some((conditionGroup) =>
        conditionGroup.every((privilege) => userPrivs.includes(privilege))
      );
    },
    [userData]
  );

  return { havePermissions };
};
