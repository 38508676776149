import { action, makeObservable, observable } from 'mobx';

import { useDataConvertToLong } from '@/hooks/DateConverter';
import { ErrorsReportType, HeaderInfoType } from '@/types';
import { JobInfoResponse } from '@/apiTypes';

class HeaderModel {
  @observable isHeaderActionLoaded = false;
  @observable date: string;
  @observable errors: ErrorsReportType[] | null;
  @observable info: HeaderInfoType[] | null;

  constructor() {
    makeObservable(this);
    this.date = '';
    this.errors = null;
    this.info = null;
  }

  @action setActionLoaded = (isLoaded: boolean) => {
    this.isHeaderActionLoaded = isLoaded;
  };

  @action setDate = (date: string) => {
    this.date = useDataConvertToLong(date);
  };

  @action setErrors = (errors: ErrorsReportType[] | null) => {
    this.errors = errors || [];
  };

  @action setInfo = (info: JobInfoResponse) => {
    if (info) {
      const keys = Object.keys(info);
      this.info = keys.map((key) => ({ name: key, description: info[key] }));

      return;
    }
    this.info = null;
  };
}

export default HeaderModel;
