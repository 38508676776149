import { action, makeObservable, observable } from 'mobx';

import pairListModel, { PairListModel } from './ResultTable/pairList.model';

import { ProgressStages } from '@/types';

interface IResultModel {
  stage: ProgressStages | null;
}

class ResultModel {
  @observable pairListModel: PairListModel;
  @observable result: IResultModel = { stage: null };
  @observable isResultLoaded = false;

  constructor() {
    this.pairListModel = pairListModel;
    makeObservable(this);
  }

  @action setResultLoaded = (isLoaded: boolean) => {
    this.isResultLoaded = isLoaded;
  };

  @action setResult = (jobStatus: ProgressStages | null) => {
    this.result = {
      stage: jobStatus,
    };
  };
}

export default ResultModel;
