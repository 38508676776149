import { Navigate, useLocation } from 'react-router-dom';

import React from 'react';

import { useDataStore } from '@/Providers/StoreProvider';
import { ROUTES } from '@/types';

type Props = {
  children: JSX.Element;
};

const RequireAuth: React.FC<Props> = ({ children }) => {
  const { userStore } = useDataStore();
  const isAuthenticated = userStore.userData.auth;
  const needNewPassword = userStore.userData.newPasswordRequired;
  const location = useLocation();

  return isAuthenticated && !needNewPassword ? (
    children
  ) : (
    <Navigate to={ROUTES.AUTH} replace state={{ path: location.pathname, needNewPassword }} />
  );
};

export default RequireAuth;
