import { StatusCodes } from 'http-status-codes';

import rootApiService from '@/services/root.api.service';

import {
  CallAPIType,
  METHODS,
  SnackbarActionType,
  TosterOptionsType,
  IGetReportEtalonsProps,
  IPutNormalizedProps,
  IPutReportMarkerProps,
} from '@/types';
import { ApiError } from '@/utils/ApiError';
import { CounterFilter } from '@/stores/filter.model';
import {
  ISqErrorsResponse,
  IJobCreateResponse,
  ISearchQuery,
  JobInfoResponse,
  ISearchQueryCreateRequest,
  IT4FaceSearchReportEtalonPage,
  IT4FaceSearchBaseInfo,
} from '@/apiTypes';
import { createUriString } from '@/utils';

type UriMap = Record<string, string | number | boolean | null | undefined>;

class SearchQueryService {
  private makeOptions(alertMessage: string, snackbarAction: SnackbarActionType): TosterOptionsType {
    return {
      snackbarAction,
      message: alertMessage,
    };
  }

  createSearchQuery = async (
    query: ISearchQueryCreateRequest,
    snackbarAction: SnackbarActionType
  ) => {
    const tosterOptions = {
      snackbarAction,
      message: 'ошибка создания поискового запроса:',
    };
    const APIOptions: CallAPIType = {
      path: 'sq',
      method: METHODS.POST,
      body: query,
      tosterOptions,
    };

    const apiResponse = await rootApiService.callAPI<IJobCreateResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  };

  async getSearchQueryTargets(id: number, snackbarAction: SnackbarActionType) {
    const uriMap: UriMap = Object.create({});
    uriMap['sq_id'] = id;
    const uriParams = createUriString(uriMap);
    const path = `sq/report/targets${uriParams || ''}`;
    const APIOptions: CallAPIType = {
      path,
      method: METHODS.GET,
      tosterOptions: this.makeOptions('ошибка получения списка целевых файлов:', snackbarAction),
    };

    const apiResponse = await rootApiService.callAPI<IT4FaceSearchBaseInfo>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      return res;
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  }

  async getSearchQueryEtalons(data: IGetReportEtalonsProps, snackbarAction: SnackbarActionType) {
    const uriMap: UriMap = Object.create({});
    uriMap['sq_id'] = data.sq_id;
    uriMap['target'] = data.target;
    uriMap['page'] = data.page ?? 1;
    uriMap['size'] = data.size ?? 20;
    const uriParams = createUriString(uriMap);
    const path = `sq/report/etalons${uriParams || ''}`;
    const APIOptions: CallAPIType = {
      path: path,
      method: METHODS.GET,
      tosterOptions: this.makeOptions(
        'ошибка получения списка результатов по целевой модели:',
        snackbarAction
      ),
    };

    const apiResponse = await rootApiService.callAPI<IT4FaceSearchReportEtalonPage>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      return res;
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  }

  async addNormalizedImage(data: IPutNormalizedProps, snackbarAction: SnackbarActionType) {
    const path = `sq/report/etalons?sq_id=${data.sq_id}&etalon=${
      data.etalon
    }&etalon_img=${encodeURIComponent(data.etalon_img)}&normalized=${data.normalized}`;
    const APIOptions: CallAPIType = {
      path,
      method: METHODS.PUT,
      tosterOptions: this.makeOptions(
        'ошибка получения списка результатов по целевой модели:',
        snackbarAction
      ),
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.statusCode !== StatusCodes.OK) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  }

  async getSearchQuerySmallErrors(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/report/errors?sq_id=${id}`,
      method: METHODS.GET,
      tosterOptions: this.makeOptions(
        'ошибка загрузки информации о некритических ошибках:',
        snackbarAction
      ),
    };

    const apiResponse = await rootApiService.callAPI<ISqErrorsResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }

  async getSearchQuery(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/${id}`,
      method: METHODS.GET,
      tosterOptions: this.makeOptions(
        'ошибка загрузки информации о поисковом запросе:',
        snackbarAction
      ),
    };

    const apiResponse = await rootApiService.callAPI<ISearchQuery>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }

  async getSearchQueryInfo(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/info/${id}`,
      method: METHODS.GET,
      tosterOptions: this.makeOptions(
        'ошибка загрузки информации о поисковом запросе:',
        snackbarAction
      ),
    };

    const apiResponse = await rootApiService.callAPI<JobInfoResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }

  async getSearchQueryFiles(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/request/${id}`,
      method: METHODS.GET,
      tosterOptions: this.makeOptions('ошибка загрузки json-а поискового запроса:', snackbarAction),
    };

    const apiResponse = await rootApiService.callAPI<ISearchQueryCreateRequest>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }

  async getSearchQueryList(counter: CounterFilter, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/all?result_filter=${counter || CounterFilter.ALL}`,
      method: METHODS.GET,
      tosterOptions: this.makeOptions('ошибка загрузки списка поисковых запросов:', snackbarAction),
    };

    const apiResponse = await rootApiService.callAPI<ISearchQuery[]>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }

  async cancelSearchQuery(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/${id}/cancel`,
      method: METHODS.PUT,
      tosterOptions: this.makeOptions('ошибка отмены поискового запроса:', snackbarAction),
      ignoreBody: true,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  }

  async deleteSearchQuery(id: number, snackbarAction: SnackbarActionType) {
    const APIOptions: CallAPIType = {
      path: `sq/${id}`,
      method: METHODS.DELETE,
      tosterOptions: this.makeOptions('ошибка удаления поискового запроса:', snackbarAction),
      ignoreBody: true,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  }

  async changeSearchQueryReportMarker(
    data: IPutReportMarkerProps,
    snackbarAction: SnackbarActionType
  ) {
    const uriMap: UriMap = Object.create({});
    uriMap['sq_id'] = data.sq_id;
    uriMap['target'] = data.target;
    uriMap['etalon'] = data.etalon;
    uriMap['marker'] = data.marker;
    const uriParams = createUriString(uriMap);
    const path = `sq/report/marker${uriParams || ''}`;

    const APIOptions: CallAPIType = {
      path: path,
      method: METHODS.PUT,
      tosterOptions: this.makeOptions(
        'ошибка при изменении метки отчета поискового запроса:',
        snackbarAction
      ),
      ignoreBody: true,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    return apiResponse.statusCode === StatusCodes.OK;
  }
}

export default new SearchQueryService();
