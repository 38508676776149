import { NotificationType, showNotification } from 'stc-ui-kit';
import { NotificationParams } from './types';

export const showErrorNotification = ({
  header,
  message,
  keepOpen = false,
}: NotificationParams) => {
  showNotification({
    dataTestId: '',
    header: header || '',
    message: message || '',
    type: NotificationType.Error,
    autoCloseTimeoutSeconds: keepOpen ? 0 : undefined,
  });
};
