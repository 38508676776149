import HeaderModel from './ResultPlace/Header/header.model';
import ResultStore from './ResultPlace/result.model';
import ProcessStore from './ResultPlace/QueryProgress/progress.model';

import ExportModel from './ExportModal/export.model';

import FilterStore from '@/stores/filter.model';
import QueryStore from '@/components/queryPage/QueryTable/query.model';
import ModalStore from '@/components/queryPage/ExportModal/export.modal';

export default class QueryRootStore {
  queryStore: QueryStore;
  resultStore: ResultStore;
  headerStore: HeaderModel;
  progressStore: ProcessStore;
  filterStore: FilterStore;
  exportStore: ExportModel;
  modalStore: ModalStore;

  constructor() {
    this.queryStore = new QueryStore();
    this.resultStore = new ResultStore();
    this.headerStore = new HeaderModel();
    this.progressStore = new ProcessStore();
    this.filterStore = new FilterStore();
    this.exportStore = new ExportModel();
    this.modalStore = new ModalStore();
  }

  clear() {
    this.queryStore.clear();
  }
}
