import { HttpStatus } from 'enums';

import { authService } from '@/services/auth.service';

export interface IFetchInit extends RequestInit {
  queryParams?: Record<string, string | string[]>;
  ignoreErrors?: boolean;
  useBuildinContentTypeDetection?: boolean; // https://stackoverflow.com/questions/35192841/how-do-i-post-with-multipart-form-data-using-fetch ()
}

type TFetch = (url: RequestInfo, mockUrl?: string, options?: IFetchInit) => Promise<Response>;

export const fetch: TFetch = (url, mockUrl, options) => {
  const token = authService.getUserAuthToken();
  const {
    queryParams = {},
    headers,
    useBuildinContentTypeDetection = false,
    ignoreErrors = false,
    ...rest
  } = options || {};

  const urlSearchParams = new URLSearchParams({});

  Object.entries(queryParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => urlSearchParams.append(key, value));
    } else {
      urlSearchParams.append(key, value);
    }
  });

  const params = urlSearchParams.toString();

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  let comboUrl = `api/v1/${url}`;

  if (mockUrl) {
    comboUrl = `/mock/v1/${`${mockUrl}/data.json` || ''}`;
  } else {
    comboUrl = `${process.env.PUBLIC_URL}/${comboUrl}`;
  }

  if (params) {
    comboUrl += `?${params}`;
  }

  let resultHeaders: HeadersInit = {};
  if (!useBuildinContentTypeDetection) {
    resultHeaders['Content-Type'] = 'application/json; charset=utf-8';
  }
  if (token) {
    resultHeaders.Authorization = `Bearer ${token}`;
  }
  // if (tenantId) {
  //   resultHeaders.TenantId = tenantId;
  // }
  resultHeaders = { ...resultHeaders, ...headers };

  const fetchRequestOptions: RequestInit = {
    headers: resultHeaders,
    cache: 'no-cache',
    ...rest,
  };

  if (ignoreErrors) {
    return window.fetch(`${process.env.PUBLIC_URL}/${comboUrl}`, fetchRequestOptions);
  }

  // eslint-disable-next-line require-await
  return window.fetch(comboUrl, fetchRequestOptions).then((response: Response) => {
    if (response.status !== HttpStatus.OK) {
      if (
        [
          HttpStatus.BAD_GATEWAY,
          HttpStatus.SERVICE_UNAVAILABLE,
          HttpStatus.GATEWAY_TIMEOUT,
        ].includes(response.status)
      ) {
        console.log('error', response.statusText);
      }
    }

    return response;
  });
};

export function createUriString(
  paramsArray: Record<string, string | number | boolean | null | undefined>
) {
  if (!paramsArray) {
    return null;
  }
  const paramKeys = Object.keys(paramsArray);
  const uriArray: string[] = [];
  paramKeys.forEach((key) => {
    const value = paramsArray[key];
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const newParam = encodeURI(`${key}=${value}`);

    if (value !== undefined) {
      uriArray.push(newParam);
    }
  });

  if (uriArray.length < 1) {
    return null;
  }

  return `?${uriArray.join('&')}`;
}
