import { SearchQueryListStore } from './queryTable/request.model';

export class SearchReqestRootStore {
  requestListStore: SearchQueryListStore;

  constructor() {
    this.requestListStore = new SearchQueryListStore();
  }

  clear() {
    this.requestListStore.clear();
  }
}
