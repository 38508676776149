import {
  CardFileCreatedSseMessage,
  CardFileReplacedSseMessage,
  CardFilesDeletedSseMessage,
  CardFileUpdatedSseMessage,
  CardFoundInHotlistSseMessage,
  CardInteractiveAutoModeCompletedSseMessage,
  CardSseMessage,
  FileProcessChangedSseMessage,
  FileProcessDeletedSseMessage,
  SseMessage,
  SseMessageType,
  VadSseMessage,
} from '@/types/sse';
import { makeAutoObservable } from 'mobx';

class SseStore {
  constructor() {
    makeAutoObservable(this);
  }

  isReconnectedAfterError = false;
  currentSseConnectionId = '';
  cardMessage: CardSseMessage | null = null;

  cardFileCreatedMessage: CardFileCreatedSseMessage | null = null;
  cardFileUpdatedMessage: CardFileUpdatedSseMessage | null = null;
  cardFilesDeletedMessage: CardFilesDeletedSseMessage | null = null;
  cardFileReplacedMessage: CardFileReplacedSseMessage | null = null;

  cardFoundInHotlistMessage: CardFoundInHotlistSseMessage | null = null;

  vadSseMessage: VadSseMessage | null = null;
  cardInteractiveAutoModeCompletedSseMessage: CardInteractiveAutoModeCompletedSseMessage | null =
    null;

  fileProcessChangedSseMessage: FileProcessChangedSseMessage | null = null;
  fileProcessDeletedSseMessage: FileProcessDeletedSseMessage | null = null;

  setIsReconnectedAfterError = (value: boolean) => {
    this.isReconnectedAfterError = value;
  };

  setCardFoundInHotlistMessage = (message: CardFoundInHotlistSseMessage | null) => {
    this.cardFoundInHotlistMessage = message;
  };

  setCurrentSseConnectionId = (value: string) => {
    this.currentSseConnectionId = value;
  };

  setMessage = (message: SseMessage) => {
    switch (message?.type) {
      case SseMessageType.CardUpdated:
        this.cardMessage = message;
        break;

      case SseMessageType.CardFileCreated:
        this.cardFileCreatedMessage = message;
        break;

      case SseMessageType.CardFileUpdated:
        this.cardFileUpdatedMessage = message;
        break;

      case SseMessageType.CardFilesDeleted:
        this.cardFilesDeletedMessage = message;
        break;

      case SseMessageType.CardFileReplaced:
        this.cardFileReplacedMessage = message;
        break;

      case SseMessageType.CardFoundInHotlist:
        this.setCardFoundInHotlistMessage(message);
        break;

      case SseMessageType.GUID:
        this.setCurrentSseConnectionId(message.guid);
        break;

      case SseMessageType.VAD:
        this.vadSseMessage = message;
        break;

      case SseMessageType.CardInteractiveAutoModeCompleted:
        this.cardInteractiveAutoModeCompletedSseMessage = message;
        break;

      case SseMessageType.FileProcessChanged:
        this.fileProcessChangedSseMessage = message;
        break;

      case SseMessageType.FileProcessDeleted:
        this.fileProcessDeletedSseMessage = message;
        break;

      default:
        break;
    }
  };
}

export default SseStore;
