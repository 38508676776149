export enum COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
  INFO = 'info',
}

export enum VARIANT {
  OUTLINED = 'outlined',
  FILLED = 'filled',
  CONTAINED = 'contained',
  DEFAULT = 'default',
  TEXT = 'text',
}
