import { CSSObject } from 'tss-react';

import { COLOR } from '@/Providers/ThemeProvider/types';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const iconStyles: Record<string, CSSObject> = {
  [COLOR.PRIMARY]: {
    color: appColors.success,
  },
  [COLOR.WARNING]: {
    color: appColors.warning,
  },
  [COLOR.SECONDARY]: {
    color: appColors.white,
  },
  [COLOR.ERROR]: {
    color: appColors.error,
  },
  [COLOR.DEFAULT]: {},
};

export const defaultIconStyles: CSSObject = {};
