import { makeObservable, observable, action, computed } from 'mobx';
import {
  CardTemplate,
  CardTemplateData,
  CustomType,
  CustomTypeFieldDescriptor,
  FieldDescriptor,
  ISideFilterField,
} from '@/types/cardTemplate';
import { cardTemplateService } from '@/services/cardTemplate.service';
import { SideFilterStore } from '@/modules/multipass/sideFilter.store';

export class TemplatesStore {
  // схема полей для картотеки
  _templatesSystemFields: FieldDescriptor[] = observable.array<FieldDescriptor>();

  // список существующих шаблонов карточек
  _templates: CardTemplate[] = observable.array<CardTemplate>();

  /*
  0 - initial, 1 - loading, 2 - loaded, 3 - error
   */
  private _loadingStatus: 0 | 1 | 2 | 3 = 0;

  /**
   * эти поля нужны для обработки случая, когда initFilingCabinetScheme вызывается
   * во время запроса данных с сервера (reloadScheme, _loadingStatus === 1).
   * Вызов initFilingCabinetScheme будет дожидаться выполнения запроса в reloadScheme.
   */
  private _loadingPromise: Promise<null> | null = null;
  private _resolveLoadingPromise: (value: PromiseLike<null> | null) => void = () => null;

  sideFilterStore: SideFilterStore = new SideFilterStore([]);

  constructor() {
    makeObservable(
      this,
      {
        _templatesSystemFields: observable,
        _templates: observable,
        sideFilterStore: observable,

        reloadScheme: action,

        templatesSystemFields: computed,
        templates: computed,
        defaultTemplate: computed,
        sideFilterFields: computed,
      },
      { autoBind: true }
    );
  }

  private async initFilingCabinetScheme() {
    if (this._loadingStatus > 1) {
      return;
    }

    if (this._loadingStatus === 1) {
      if (!this._loadingPromise) {
        this._loadingPromise = new Promise((resolve, reject) => {
          this._resolveLoadingPromise = resolve;
        });
      }

      await this._loadingPromise;

      return;
    }

    await this.reloadScheme();
  }

  // Перезагрузить схему картотеки
  reloadScheme = async () => {
    try {
      this._loadingStatus = 1;
      const response = await cardTemplateService.getFilingCabinetScheme();
      if (response.res) {
        this._templatesSystemFields = [...response.res.systemFields];
        this._templates = [...response.res.templates];
        this.sideFilterStore = new SideFilterStore(this.sideFilterFields);
        this._loadingStatus = 2;
      } else {
        this._loadingStatus = 3;
        console.error('getFilingCabinetScheme response is empty');
      }
    } catch (e) {
      this._loadingStatus = 3;
      console.error(e);
    } finally {
      this._resolveLoadingPromise(null);
    }
  };

  // Список системных полей картотеки
  get templatesSystemFields(): FieldDescriptor[] {
    void this.initFilingCabinetScheme();

    return this._templatesSystemFields;
  }

  // список шаблонов
  get templates(): CardTemplate[] {
    void this.initFilingCabinetScheme();

    return this._templates;
  }

  // шаблон по умолчанию
  get defaultTemplate(): CardTemplate | null {
    void this.initFilingCabinetScheme();

    return this._templates.find((t) => t.default) ?? null;
  }

  // поля для построения бокового фильтра
  get sideFilterFields(): ISideFilterField[] {
    void this.initFilingCabinetScheme();

    const map: Map<string, ISideFilterField> = new Map<string, ISideFilterField>();
    for (const template of this._templates) {
      const templateFieldsMap: Map<string, ISideFilterField> =
        this.getFieldsForFilterFromTemplateInOrder(template.data);

      for (const [key, value] of templateFieldsMap.entries()) {
        if (value.field.mapping) {
          const dictionary = template.data.dictionaries?.find((d) => d.id === value.field.mapping);
          if (dictionary) {
            value.field.dictionary = dictionary;
          }
        }
        map.set(key, value);
      }
    }

    const result: ISideFilterField[] = [];
    map.forEach((value) => result.push(value));

    return result;
  }

  // шаблон по умолчанию
  getDefaultTemplate = async (): Promise<CardTemplate | null> => {
    await this.initFilingCabinetScheme();

    return this.defaultTemplate;
  };

  // шаблон по идентификатору
  getTemplateById = async (argId: number): Promise<CardTemplate | null> => {
    await this.initFilingCabinetScheme();

    return this._templates.find((t) => t.id === argId) ?? null;
  };

  private getFieldsForFilterFromTemplateInOrder(
    value: CardTemplateData
  ): Map<string, ISideFilterField> {
    const result: Map<string, ISideFilterField> = new Map<string, ISideFilterField>();

    value.filterOrder.forEach((fieldId) => {
      const field: FieldDescriptor | null =
        value.fields.find((field) => field.id === fieldId) ?? null;

      if (field != null) {
        const sideFilterField: ISideFilterField = { fieldId, field };
        if (field.type === 'ListObject') {
          const fieldInOrder = value.customTypes?.find((t) => t.id === field.customType) ?? null;
          if (fieldInOrder != null) {
            sideFilterField.children = this.getFieldsForFilterFromCustomTypeInOrder(fieldInOrder);
          }
        }
        result.set(fieldId, sideFilterField);
      }
    });

    return result;
  }

  private getFieldsForFilterFromCustomTypeInOrder(value: CustomType): ISideFilterField[] {
    const result: ISideFilterField[] = [];

    value.filterOrder.forEach((fieldId) => {
      const fieldInOrder: CustomTypeFieldDescriptor | null =
        value.fields.find((field) => field.id === fieldId) ?? null;
      if (fieldInOrder != null) {
        result.push({ fieldId, field: fieldInOrder });
      }
    });

    return result;
  }
}
