import { format, formatRelative, isDate } from 'date-fns';
import { ru } from 'date-fns/locale';

export const useDateConvertToShort = (value?: string) => {
  return convertDateToShort(value);
};

export const convertDateToShort = (value?: string) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  const isValidateDate = isDate(date);

  if (isValidateDate) {
    return format(date, 'dd.MM.yyyy');
  }

  return value;
};

export const useTimeConverter = (value?: string) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  const isValidateDate = isDate(date);

  if (isValidateDate) {
    return format(date, 'HH:mm');
  }

  return value;
};

export const useDataConvertToLong = (value?: string) => {
  if (!value) {
    return '';
  }
  const date = new Date(value);
  const isValidateDate = isDate(date);

  if (isValidateDate) {
    return formatRelative(date, new Date(), {
      locale: ru,
    }).toString();
  }

  return value;
};
