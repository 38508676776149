export class PlayerRegion {
  constructor(
    public id: number,
    public start: number,
    public end: number,
    public loop: boolean,
    public drag: boolean,
    public resize: boolean,
    public color: string,
    public channelIdx: number
  ) {}
}

export type PlayerRegions = PlayerRegion[];
