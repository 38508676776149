import bind from '@chbrown/bind';
import { makeObservable, observable } from 'mobx';

class CMockStore {
  constructor() {
    makeObservable(this);
  }
  @observable backendMode = false;

  @bind
  setBackendMode(value: boolean): void {
    this.backendMode = value;
  }
}

export const MockStore = new CMockStore();
