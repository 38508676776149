import { GridSelectionModel } from '@mui/x-data-grid';
import { action, makeObservable, observable } from 'mobx';

import { ColumnPropsType, ICouple, InfoType, PagingType, PairType } from '@/types';

export const defaultPaging = { page: 1, size: 20, pages_total: 1 };

export class PairListModel {
  @observable rows: PairType[] = [];
  @observable paging: PagingType = defaultPaging;
  @observable columns: ColumnPropsType[] | [] = [];
  @observable selectionModel: GridSelectionModel = [];
  hiddenColumns: string[] | null | number[] = [];

  @action setInfo = ({ columns, hidden }: InfoType) => {
    this.columns = columns;
    this.hiddenColumns = hidden;
  };
  @action setRows = (rows: PairType[]) => {
    this.rows = rows;
  };
  @action setReports = ({ pairs, paging }: ICouple, isPagination: boolean) => {
    if (isPagination && paging.page > 1) {
      this.rows = this.rows.length > 0 ? this.rows.concat(pairs) : pairs;
    } else {
      this.rows = pairs;
    }
    this.paging = paging;
  };
  @action changePaging = (paging: PagingType) => {
    this.paging = paging;
  };
  @action changePage = (page: number) => {
    this.paging.page = page;
  };
  @action resetPaging = () => {
    this.paging = defaultPaging;
  };
  @action setSelectionModel = (rowId: GridSelectionModel) => {
    this.selectionModel = rowId;
  };
  @action setPageIfOutOfRange = (page: number) => {
    if (page > this.paging.pages_total && this.paging.pages_total !== 1) {
      this.paging.page = this.paging.pages_total;
    } else {
      this.paging.page = defaultPaging.page;
    }
  };

  constructor() {
    makeObservable(this);
  }

  clear = () => {
    this.setRows([]);
    this.resetPaging();
  };
}
const PairListModelImpl = new PairListModel();
export default PairListModelImpl;
