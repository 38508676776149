import rootApiService from './root.api.service';
import { CONSTANTS, CallAPIType, METHODS, SnackbarActionType } from '@/types';
import { ApiError } from '@/utils/ApiError';

class ConstantsService {
  async getConstantValue<T>(
    param: CONSTANTS,
    errorMessage?: string,
    snackbarAction?: SnackbarActionType
  ): Promise<T> {
    const APIOptions: CallAPIType = {
      path: `const?param=${param}`,
      method: METHODS.GET,
      tosterOptions: snackbarAction
        ? {
            snackbarAction,
            message: errorMessage,
          }
        : undefined,
      body: null,
    };

    const apiResponse = await rootApiService.callAPI<{ value: T }>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res.value;
  }

  async saveConstantValue<T>({
    isOwned,
    param,
    value,
  }: {
    param: CONSTANTS;
    value: T;
    isOwned: boolean;
  }) {
    return await rootApiService.callAPI({
      path: `const?param=${param}&isOwned=${String(isOwned)}`,
      method: METHODS.PUT,
      body: value,
    });
  }
}

export const constantsService = new ConstantsService();
