export const validateEmail = (email: string): boolean => {
  if (email.includes(' ')) {
    return false;
  }

  if (!email.includes('@')) {
    return false;
  }

  if (email.startsWith('.') || email.endsWith('.')) {
    return false;
  }

  const [username, domain] = email.split('@');

  if (!username || !domain) return false;

  if (/[\u0400-\u04FF]/.test(email)) {
    return false;
  }

  if (username.includes('..')) {
    return false;
  }

  const specialChars = /[!#$%&'*+/=?^_`{|}~]/;
  if (specialChars.test(domain)) {
    return false;
  }

  if (!domain.includes('.')) {
    return false;
  }

  return true;
};
